import * as firebase from "firebase";

require("firebase/firestore");

const firebaseConfig = {
	apiKey: "AIzaSyDpy1LQ0Dj-g09kW42C5ypPFCCGpbIr3_U",
	authDomain: "micatalogovirtual-7bfd1.firebaseapp.com",
	projectId: "micatalogovirtual-7bfd1",
	storageBucket: "micatalogovirtual-7bfd1.appspot.com",
	messagingSenderId: "232830642721",
	appId: "1:232830642721:web:69e6788956c4a59f3fd160"
};

const initializedFirebaseApp = firebase.initializeApp(firebaseConfig);
let messaging = {
	requestPermission: () => {
		let miPrimeraPromise = new Promise((resolve, reject) => {
		  setTimeout(function(){
			resolve("¡Éxito!");
		  }, 250);
		});
		return miPrimeraPromise;
	},
	getToken: () => {
		return true;
	}
};
if (firebase.messaging.isSupported()) {
	const d = initializedFirebaseApp.messaging();
	d.getToken({
		vapidKey: 'BAmpNqPxBzcPURWTdr6UJocSi5AR4EDlnEF3wGog0e52CS4YtiodANFaNFRvc6onb-Z9LwR32TLoITLSZ8vGf44'
	}).catch(err => {
		console.log(err)
	})
	
}
export { messaging };

export const db = firebase.firestore();


export const getTiendaInfo = (idtienda) => {
	// console.log(idtienda)
	// console.log(idtienda); return true;
	var tiendaRef = firebase.firestore().collection('stores').where('tienda', '==', idtienda).where('active', '==', true);
	return tiendaRef
		.get()
		.then(snap => {
			if (!snap.empty) {
				const tiendaPrev = snap.docs[0];
				var finalRef = firebase.firestore().collection('clients').doc(tiendaPrev.id);
				return finalRef
					.get()
					.then(info => {
						if (info.exists) {
							return {
								...info.data(),
								id: tiendaPrev.id
							}
						}
					})
			} else {
				throw new Error('No ha cargado el documento');
			}
		})
		.catch(err => {
			throw new Error('No ha cargado el documento');
			// throw new Error('Error inesperado');
			// return error;
		})
};

/**
 *
 * @param {*} param0
 */
export const queryObjectCollection = ({ collection, currentStore }) => {
	if (currentStore && currentStore.hasOwnProperty('id')) {
		  let currentUserId = currentStore.id;
		  let collectionRef = firebase.firestore().collection('clients').doc(currentUserId).collection(collection);

		  let results = [];

		  return (
			collectionRef
			  .orderBy('created', 'desc')
			  .where('status', '==', 1)
			  .get()
			  .then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
				  // doc.data() is never undefined for query doc snapshots
				  results.push({
					id: doc.id,
					...doc.data(),
				  });
				});
				return results;
			  })
			  .catch((error) => {
				console.log("Error getting documents: ", error);
				return error;
			  })
		  );
	}
};

export const queryObjectCollectionByPosition = ({ collection, currentStore }) => {
	if (currentStore && currentStore.hasOwnProperty('id')) {
		  let currentUserId = currentStore.id;
		  let collectionRef = firebase.firestore().collection('clients').doc(currentUserId).collection(collection);

		  let results = [];

		  return (
			collectionRef
			  .orderBy('position', 'asc')
			  //.where('owner', '==', currentUserId)
			  .get()
			  .then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
				  // doc.data() is never undefined for query doc snapshots
				  results.push({
					id: doc.id,
					...doc.data(),
				  });
				});
				return results;
			  })
			  .catch((error) => {
				console.log("Error getting documents: ", error);
				return error;
			  })
		  );
	}
};

export const queryObjectCollectionSearch = ({ collection, currentStore, id }) => {
	if (currentStore && currentStore.hasOwnProperty('id')) {
		  let currentUserId = currentStore.id;
		  let collectionRef = firebase.firestore().collection('clients').doc(currentUserId).collection(collection).doc(id);
		  return (
			collectionRef
			  //.where('owner', '==', currentUserId)
			  .get()
			  .then((querySnapshot) => {
				  return {
					...querySnapshot.data()
				  };
				  // console.log(querySnapshot, querySnapshot.data());
				// querySnapshot.forEach((doc) => {
				  // doc.data() is never undefined for query doc snapshots
				  // return {
					// id: doc.id,
					// ...doc.data(),
				  // };
				// });
			  })
			  .catch((error) => {
				console.log("Error getting documents: ", error);
				return error;
			  })
		  );
	}
};

export const queryObjectCollection2 = ({ collection, currentStore }) => {
  let currentUserId = currentStore.id;
  let collectionRef = firebase.firestore().collection('clients').doc(currentUserId).collection(collection);

  let results = [];

  return (
    collectionRef
	  .orderBy('created', 'desc')
      //.where('owner', '==', currentUserId)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          results.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        return results;
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
        return error;
      })
  );
};

/**
 *
 * @param {*} _collection - name of collection to add object to
 * @param {*} _objectData - data to add to the collection
 */
export const addObjectToCollection = ({ collection, objectData, currentStore}) => {
  let currentUserId = currentStore.id;
  let collectionRef = firebase.firestore().collection('clients').doc(currentUserId).collection(collection);
	
	let tempObj = {
		created: new Date().getTime(),
		updated: new Date().getTime(),
	};
	
  return collectionRef
    .add({
		...objectData,
		...tempObj
    })
    .then(
      async (doc) => {
        // console.log(`addObjectToCollection ${collection} ${doc}`);

        let docData = await getByRef(doc);
        return docData;
      },
      (error) => {
        console.log(`ERROR: addObjectToCollection ${collection} ${error}`);
        return error;
      }
    )
    .catch((e) => {
      console.log(`ERROR: addObjectToCollection ${collection} ${e}`);
      return e;
    });
};

/**
 *
 * @param {*} _collection - name of collection to update object to
 * @param {*} _objectData - data to set to the collection
 */
export const setObjectToCollection = ({ collection, id, objectData, currentStore }) => {
  let currentUserId = currentStore.id;
  let collectionRef = firebase.firestore().collection('clients').doc(currentUserId).collection(collection).doc(id);
  // console.log(id, objectData);
	// let tempObj = {
		// updated: new Date().getTime(),
	// };
	
  return collectionRef
    .update(objectData)
    .then(
      async () => {
        let docData = await getByRef(collectionRef);
        return docData;
      },
      (error) => {
        console.log(`ERROR: setObjectToCollection ${collection} ${error}`);
        return error;
      }
    )
    .catch((e) => {
      console.log(`ERROR: setObjectToCollection ${collection} ${e}`);
      return e;
    });
};

export const saveNewStore = (store) => {
	let currentUserId = firebase.auth().currentUser.uid;
	let ref = firebase.firestore().collection('stores').doc(currentUserId);
	
	let tempObj = {
		updated: new Date().getTime(),
		tienda: store
	};
	
	return ref
    .set({
		...tempObj
    })
    .then(
      async (doc) => {
			let docData = await getByRef(ref);
			return docData;
      },
      (error) => {
        console.log(`ERROR: saveNewStore ${store} ${error}`);
        return error;
      }
    )
    .catch((e) => {
      console.log(`ERROR: saveNewStore ${store} ${e}`);
      return e;
    });
}



/**
 *
 * @param {*} collection - name of collection
 * @param {*} objectId - id of data to remove from the collection
 */
export const removeObjectFromCollection = ({ collection, objectId }) => {
  let currentUserId = firebase.auth().currentUser.uid;
  let collectionRef = firebase.firestore().collection('clients').doc(currentUserId).collection(collection);

  return collectionRef
    .doc(objectId)
    .delete()
    .then(
      async (doc) => {
        console.log(`removeObjectFromCollection ${collection} ${objectId}`);
        return true;
      },
      (error) => {
        console.log(`ERROR: removeObjectFromCollection ${collection} ${error}`);
        return error;
      }
    )
    .catch((e) => {
      console.log(`ERROR: removeObjectFromCollection ${collection} ${e}`);
      return e;
    });
};

export const checkIfStoreExists = (_store) => {
	let ref = firebase.firestore().collection('stores');
	let results = [];
	
	return ref
		.where('tienda', '==', _store)
		.get()
		.then((docs) => {
			docs.forEach((doc) => {
			  // doc.data() is never undefined for query doc snapshots
			  results.push({
				id: doc.id,
				...doc.data(),
			  });
			});
			// console.log(results);
			return { ...results[0] };
		})
		.catch((error) => {
			return error;
		})
}

export const getByRef = (_documentRef) => {
  return _documentRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        return { ...doc.data(), id: _documentRef.id };
      } else {
        // doc.data() will be undefined in this case
        // console.log("No such document!");
        return null;
      }
    })
    .catch((error) => {
      console.log("Error getting document:", error);
      return error;
    });
};

/**
 *
 * @param {*} blob
 */
export const uploadImage = (blob) => {
  return new Promise((resolve, reject) => {
    let currentUserId = firebase.auth().currentUser.uid;
    const ref = firebase
      .storage()
      .ref(currentUserId)
      .child(new Date().getTime() + "-" + currentUserId + ".jpeg");

    const task = ref.put(blob);

    task.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) =>
        console.log((snapshot.bytesTransferred / snapshot.totalBytes) * 100),
      (error) => {
        console.log("error", error);
        return reject(error);
      },
      (result) => {
        return resolve({
          url: task.snapshot.downloadURL,
          contentType: task.snapshot.metadata.contentType,
          name: task.snapshot.metadata.name,
          size: task.snapshot.metadata.size,
        });
      }
    );
  });
};


export const uploadFile = (path, blob) => {
  return new Promise((resolve, reject) => {
    // let currentUserId = firebase.auth().currentUser.uid;
	
	let storage = firebase.storage().ref(path);
	const task = storage.put(blob);

    task.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) =>
        console.log((snapshot.bytesTransferred / snapshot.totalBytes) * 100),
      (error) => {
        console.log("error", error);
        return reject(error);
      },
      (result) => {
        return resolve({
          url: task.snapshot.downloadURL,
          contentType: task.snapshot.metadata.contentType,
          name: task.snapshot.metadata.name,
          size: task.snapshot.metadata.size,
        });
      }
    );
  });
};


export const saveInClientProfile = (registros) => {
	let user = firebase.auth().currentUser;

	var userRef = firebase.firestore().collection("clients").doc(user.uid);
	return userRef
		.update(registros)
		.then((response) => {
			// console.log(response);
		})
		.catch((error) => {
		  console.log("Error getting document:", error);
		});
}


