import { useParams, useHistory } from "react-router";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonImg,
  IonIcon,
  IonModal,
  IonSkeletonText,
  IonRippleEffect,
  IonTitle
} from "@ionic/react";
import { Button, Typography, Grid, Container } from '@material-ui/core';
import { homeOutline, arrowBackOutline } from 'ionicons/icons';
import RetrieveImage from './../retrieveImg';
import * as firebaseService from "./../store/firebaseService";

import { returnPrice } from '../utils';

const RevisionPage = ({showRevisionModal, tienda}) => {
	const params = useParams();
	const history = useHistory();
	
	const [totalToPay, setTotalToPay] = useState(0);
	
	const [pedido, setPedido] = useState({
		ciudad: '',
		created: 0,
		nombre: '',
		products: {},
		telefono: '',
		totalpagado: 0
	});
	
	useEffect(() => {
		async function call() {
			if (tienda && tienda.nombreempresa && params.controller && params.controller === 'revision') {
				var idOrder = params.id;
				const order = await firebaseService.queryObjectCollectionSearch({ collection: "orders", currentStore: tienda, id: idOrder });
				setPedido(order);
				getNewTotal(order.products);
			}
		}
		call();
	}, [tienda, params]);
	
	const getNewTotal = (cart) => {
		var total = 0;
		for (let i in cart) {
			total += cart[i].total;
		}
		setTotalToPay(total);
	}
	
	
	const _renderCartItems = () => {
		const cartToUse = {...pedido.products};
		return Object.keys(cartToUse).map((k) => {
			const cart = cartToUse[k];
			return (
				<div className="cardNueva" key={k}>
					<Grid container justify="flex-start" spacing={2}>
						<Grid item xs={4}>
							{
								(cart.cover && typeof cart.cover === 'string' && cart.cover !== '') ?
								<Link to={"/product/" + cart.id}><IonImg src={RetrieveImage(cart.cover)} className="cartImg" /></Link> :
								((cart.cover && typeof cart.cover === 'object') ? 
									<Link to={"/product/" + cart.id}><IonImg src={RetrieveImage(cart.cover[0])} className="cartImg" /></Link> :
									<IonSkeletonText animated className="cartExample" />
								)
							}
						</Grid>
						<Grid item xs={8} className="containerForCartInfo">
							<Typography variant="body1" className="tituloname" component="p">
								{cart.name.toUpperCase()}
							</Typography>
							{cart.hasOwnProperty('reference') && cart.reference ? (
								<Typography variant="body2" component="p" className="infomini">
									<strong>Referencia:</strong> {cart.reference}
								</Typography>	
							) : ''}
							<Typography variant="body2" component="p" className="infomini">
								<strong>Cantidad:</strong> {cart.quantity}
							</Typography>
							{tienda.hasOwnProperty('visibility') && tienda.visibility.includes('precio') ? <Typography color="textSecondary" variant="body2" component="p" className="infomini">
								<strong>Precio x1:</strong> $ {returnPrice(cart.price)}
							</Typography> : null}
							{cart.hasOwnProperty('attributes') && cart.attributes.length ? (<Typography className="infomini" color="textSecondary" variant="body2" component="p">
								<strong>Atributos:</strong> {cart.attributes}
							</Typography>) : ''}
							{cart.hasOwnProperty('adiciones') && cart.hasOwnProperty('adicionesInfo') && cart.adiciones !== null && cart.adicionesInfo !== null && Object.keys(cart.adiciones).length ? (<><Typography className="infomini" color="textSecondary" variant="body2" component="p">
				 					<strong>Especificaciones:</strong>
				 				</Typography>
				 				<ul className="listaUl">
				 					{Object.keys(cart.adiciones).map(index => {
				 						var info = cart.adicionesInfo.find(it => it._id === index);
				 						var item = cart.adiciones[index];
										if (info) {
											return (
												<li key={info._id}>
													<strong>{info.tituloGrupo}:</strong>
													{Object.values(item).map(adi => {
														return (
															<span key={info._id + adi.adicion}>
																{adi.adicion} <i>x{adi.quantity}</i>
																{adi.extraPrice > 0 ? <strong> + ${returnPrice(adi.quantity * adi.extraPrice)}</strong> : ''}
															</span>
														)
													})}
												</li>
											)
										} else {
											return null;
										}
				 					})}	
				 				</ul>
				 				</>) : ''}
							{cart.hasOwnProperty('notes') && cart.notes.trim().length ? (<Typography className="infomini" color="textSecondary" variant="body2" component="p">
								<strong>observaciones:</strong><br /> {cart.notes}
							</Typography>) : ''}
							{tienda.hasOwnProperty('visibility') && tienda.visibility.includes('precio') ? <div className="pull-to-right">
								<span className="finalPrice">$ {returnPrice(cart.total)}</span>
							</div> : null}
						</Grid>
					</Grid>
				</div>
			);
		});
	}
	
	return (
		<IonModal mode="ios" isOpen={showRevisionModal}>
			<style>{((tienda && tienda.hasOwnProperty('paleta') && tienda.paleta.length && tienda.paleta[0]) ? `
				.volveralhome {
					background: ${tienda.paleta[0]};
				}
				
				.botonWhatsapp {
					background: #25d366;
					color: #FFF;
					text-decoration: none;
					border-radius: 50px;
					padding: 5px 20px;
					display: block;
					margin-top: 5px;
					margin-bottom: 5px;
				}
			` : [])}
			</style>
			<IonHeader className="headerProd">
				<IonToolbar mode="ios">
					<IonButtons slot="start">
						<Button onClick={() => { history.push('/'); }} type="button" className="ion-activatable ripple-parent cartHeader">
							<IonIcon icon={arrowBackOutline} />
							<IonRippleEffect></IonRippleEffect>
						</Button>
					</IonButtons>
					<IonTitle>
						Pedido <span className="nomuylargo">{params.id}</span>
					</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent className="cartSection">
				<section className="carritoInfo">
					<Typography className="textoCuantosCarritos" align="center">
						
					</Typography>
					<Container className="containerCarts">
						{ _renderCartItems() }
					</Container>
				</section>
				<div className="footerInfo">
					<Grid container justify="space-between">
						<Grid item>
							<Typography variant="body1">
								Nombres
							</Typography>
						</Grid>
						<Grid item>
							<Typography color="textSecondary" variant="body1">
								{pedido.nombre}
							</Typography>
						</Grid>
					</Grid>
					<Grid container justify="space-between" alignItems="center">
						<Grid item>
							<Typography variant="body1">
								Teléfono
							</Typography>
						</Grid>
						<Grid item>
							<Typography color="textSecondary" variant="body1">
								<a rel="noopener noreferrer" href={"//wa.me/"+pedido.telefono.replace('+', '')} target="_blank" className="botonWhatsapp">{pedido.telefono}</a>
							</Typography>
						</Grid>
					</Grid>
					{tienda.hasOwnProperty('visibility') && tienda.visibility.includes('precio') ? <Grid container justify="space-between">
						<Grid item>
							<Typography variant="h6" component="p">
								SubTotal
							</Typography>
						</Grid>
						<Grid item>
							<Typography variant="h5" align="right">
								$ {returnPrice(totalToPay)}
							</Typography>
						</Grid>
					</Grid> : null}
					{(tienda.hasOwnProperty('mostrarEnvio') && tienda.mostrarEnvio) ? (<Grid container justify="space-between">
							<Grid item>
								<Typography variant="body1">
									Envío
								</Typography>
							</Grid>
							<Grid item>
								<Typography color="textSecondary" variant="body1">
									{(tienda.hasOwnProperty('envioText') && tienda.envioText) ? tienda.envioText : '+Costos de envío'}
								</Typography>
							</Grid>
						</Grid>) : ''}
					{(tienda.hasOwnProperty('mostrarEntrega') && tienda.mostrarEntrega) ? (<Grid container justify="space-between">
							<Grid item>
								<Typography variant="body1">
									Entrega estimada
								</Typography>
							</Grid>
							<Grid item>
								<Typography color="textSecondary" variant="body1">
									{(tienda.hasOwnProperty('entregaText') && tienda.entregaText) ? tienda.entregaText : 'De 2 a 5 días'}
								</Typography>
							</Grid>
						</Grid>) : ''}
					<Grid container justify="space-between">
						<Grid item>
							<Typography variant="body1">
								Fecha del pedido
							</Typography>
						</Grid>
						<Grid item>
							<Typography color="textSecondary" variant="body1">
								{new Date(pedido.created).toLocaleDateString("es-CO")}
							</Typography>
						</Grid>
					</Grid>
					<Grid container direction="column" className="margin-top-25">
						<Link to={"/"} className="volveralhome comprarmasButton cartfooterbutton ripple-parent ion-activatable">
							<IonIcon icon={homeOutline} />Volver a la tienda
							<IonRippleEffect></IonRippleEffect>
						</Link>
					</Grid>
				</div>
			</IonContent>
		</IonModal>
	);
}

export default RevisionPage;