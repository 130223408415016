import { useParams, useHistory } from "react-router";
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
// import { useInView } from 'react-intersection-observer';

import {
  IonContent,
  IonPage,
  IonButton,
  IonImg,
  IonIcon,
  IonSkeletonText,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonItem,
  IonFab,
  IonFabButton,
  IonFabList
} from "@ionic/react";
import { Typography } from '@material-ui/core';
import {
	WhatsappShareButton,
	FacebookShareButton,
	TelegramShareButton
} from "react-share";

import { logoWhatsapp, bagHandleOutline, searchOutline, funnelOutline, shareSocialOutline, phonePortraitOutline, logoFacebook } from 'ionicons/icons';
import TelegramIcon from '@material-ui/icons/Telegram';
import {ReactComponent as ColumnIcon} from './../vectors/columns.svg';
import {ReactComponent as MicatalogoIcon } from './../vectors/micatalogo.svg';
import RetrieveImage from './../retrieveImg';
import './../styles/primerapagina.css';
import './../styles/global.css';

import ProductosPage from "./ProductosPage";
import CartPage from "./Carrito";
import OrderPage from "./Order";
import RevisionPage from "./Revision";

import PWAPrompt from 'react-ios-pwa-prompt'
import { returnPrice } from '../utils';

import * as cartService from "./../cart"; 

const HomePage = ({info}) => {
	const [columnas, setColumnas] = useState('dos');
	const [currentCategory, setCurrentCategory] = useState(null);
	const [botonActivo, setBotonActivo] = useState('dos');
	const [activeCategory, setActiveCategory] = useState('categoria_de_todos');
	
	const [showProductModal, setShowProductModal] = useState(false);
	const [showCartModal, setShowCartModal] = useState(false);
	const [showOrderModal, setShowOrderModal] = useState(false);
	const [showRevisionModal, setShowRevisionModal] = useState(false);
	
	const [carritoToModal, setCarritoToModal] = useState({});
	const [productToShow, setProductToShow] = useState(null);
	
	const [visible, setVisible] = useState([]);
	
	const [totalCarrito, setTotalCarrito] = useState(0);
	
	const [order, setOrder] = useState('limpiar');
	
	const [buscar, setBuscar] = useState(null);
	
	const [lineaToUse, setLineaToUse] = useState('');

	const [subcategoria, setSubcategoria] = useState(null);
	const [subsubcategoria, setSubsubcategoria] = useState(null);
	
	
	const params = useParams();
	const history = useHistory();

	const contentScroll = useRef(null)
	
	useEffect(() => {
		if (params.controller) {
			switch (params.controller) {
				case 'product': {
					var indexOF = findWithAttr(info.productos, 'id', params.id);
					var productoParaEnviar = info.productos[indexOF];
					if (typeof productoParaEnviar !== 'undefined') {
						setProductToShow(info.productos[indexOF]);
						setShowProductModal(true);
						setShowCartModal(false);
					} else if (info.productos.length && indexOF < 0) {
						history.push('/')
					}
					break;
				}
				case 'cart': {
					if (info.productos && info.productos.length) {
						const cartToSend = cartService.getCart();
						setShowCartModal(true);
						setCarritoToModal(cartToSend);
						setShowOrderModal(false);
					}
					break;
				}
				case 'order': {
					if (info.productos.length) {
						const cartToSend = cartService.getCart();
						setShowOrderModal(true);
						setCarritoToModal(cartToSend);
					}
					break;
				}
				case 'revision': {
					if (info.productos.length) {
						setShowProductModal(false);
						setShowCartModal(false);
						setShowOrderModal(false);
						setShowRevisionModal(true);
					}
					break;
				}
				default: {
					// alert('entro aca');
					
					setProductToShow(null);
					setShowProductModal(false);
					setShowCartModal(false);
					setShowOrderModal(false);
					setShowRevisionModal(false);
				}
			}
		} else {
			if (info.productos) {
				setProductToShow(null);
				setShowProductModal(false);
				setShowCartModal(false);
				setShowOrderModal(false);
				setShowRevisionModal(false);
			}
		}
		
		if (info.tienda && info.tienda.hasOwnProperty('visibility') && info.tienda.visibility && info.tienda.visibility.length) {
			setVisible(info.tienda.visibility);
		}
		
		let carro = cartService.getCart();
		let preCarritoTotal = Object.keys(carro);
		if (preCarritoTotal.length) {
			setTotalCarrito(preCarritoTotal.length);
		} else {
			setTotalCarrito(0);
		}
		
		if (isNumeric(params.controller)) {
			createCookie('lineaToUse', params.controller, 30);
		}
		
		var chequearLinea = readCookie('lineaToUse');
		// console.log(chequearLinea);
		if (chequearLinea === null) {
			// alert('Cargo ac');
			if (info.tienda && info.tienda.hasOwnProperty('lineas') && info.tienda.lineas && Object.keys(JSON.parse(info.tienda.lineas)).length) {
				var lineas = JSON.parse(info.tienda.lineas);
				var arrayDeLineas = Object.keys(lineas.lineas);
				if (arrayDeLineas.length) {
					var indexDeLineas = arrayDeLineas[Math.floor(Math.random() * arrayDeLineas.length)];
					createCookie('lineaToUse', lineas.lineas[indexDeLineas][1], 30);
					setLineaToUse(lineas.lineas[indexDeLineas][1]);
				}
			}
		} else {
			if (info.tienda && info.tienda.hasOwnProperty('lineas') && info.tienda.lineas && Object.keys(JSON.parse(info.tienda.lineas)).length) {
				var lineas2 = JSON.parse(info.tienda.lineas);
				var arrayDeLineas2 = Object.values(lineas2.lineas);
				if (arrayDeLineas2.length) {
					if (!JSON.stringify(arrayDeLineas2).toLowerCase().includes(chequearLinea)) {
						var indexGet = Math.floor(Math.random() * arrayDeLineas2.length);
						
						// console.log(arrayDeLineas2)
						// alert(indexGet);
						
						chequearLinea = arrayDeLineas2[indexGet][1];
						createCookie('lineaToUse', chequearLinea, 30);
						setLineaToUse(chequearLinea)
					} else {
						setLineaToUse(chequearLinea)
					}
				} else {
					setLineaToUse('');
				}
			} else {
				setLineaToUse('');
			}
		}
		
	}, [params.controller]); // eslint-disable-line react-hooks/exhaustive-deps
	
	const isNumeric = (str) => {
	  if (typeof str != "string") return false // we only process strings!  
	  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
			 !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
	}
	
	const createCookie = (name, value, days) => {
		var expires;
		if (days) {
			var date = new Date();
			date.setTime(date.getTime()+(days*24*60*60*1000));
			expires = "; expires="+date.toGMTString();
		}
		else {
			expires = "";
		}
		document.cookie = name+"="+value+expires+";";
	}
	
	const readCookie = (name) => {
		var nameEQ = name + "=";
		var ca = document.cookie.split(';');
		for(var i=0;i < ca.length;i++) {
			var c = ca[i];
			while (c.charAt(0) === ' ') {
				c = c.substring(1,c.length);
			}
			if (c.indexOf(nameEQ) === 0) {
				return c.substring(nameEQ.length,c.length);
			}
		}
		return null;
	}
	
	const findWithAttr = (array, attr, value) => {
		for(var i = 0; i < array.length; i += 1) {
			if(array[i][attr] === value) {
				return i;
			}
		}
		return -1;
	}
	
	const _renderItemsCategories = () => {
		if (info.categorias && info.categorias.length) {
			const cates = [...info.categorias];
			return cates.map((value, key) => {
			  return (
				<div key={'category_' + value.id} className="categoryItContainer">
					<IonButton onClick={() => { setCurrentCategory(value); setActiveCategory('category_' + value.id); } } className={"categoryItem editable category_" + value.id}>
						<IonImg className="imagenCategory" src={value.imagen_def ? RetrieveImage(value.imagen_def) : '/img/categoria.png'} />
							{(activeCategory === ('category_' + value.id)) ? <svg className='svgCircle' viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" style={{enableBackground:'new -580 439 577.9 194'}}
							>  
							<circle cx="50" cy="50" r="40" />
						</svg> : ''}
					</IonButton>
					<h4 className="categoryTitle">{value.name}</h4>
				</div>
			  );
			});
		}
		if (info.cargando) {
			const examples = [];
			for (let i = 0; i <= 6; i++) {
				examples.push(<div key={'example_category_' + i} className="categoryItContainer">
					<IonButton className="categoryItem editable">
						<IonSkeletonText animated style={{width:'100%', height: '100%', marginTop:'0'}} />
					</IonButton>
					<IonSkeletonText style={{width: '55px', height: '8px', marginTop: '5px', display: 'block', marginLeft: '15px'}} animated />
				</div>);
			}
			return examples;
		} else {
			return null;
		}
	};
	
	const _renderList = () => {
		return (
			<>
				<div key={"todas"} className="categoryItContainer">
					<IonButton onClick={(e) => { setCurrentCategory(null); setActiveCategory('categoria_de_todos'); }} className="categoria_de_todos categoryItem">
						{(info.tienda) ? (<><IonImg className="imagenCategory" src={RetrieveImage(info.tienda.logo_url)} />{(activeCategory === ('categoria_de_todos')) ? <svg className='svgCircle' viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" style={{enableBackground:'new -580 439 577.9 194'}}
							>  
							<circle cx="50" cy="50" r="40" />
						</svg> : ''}</>) : ''}
					</IonButton>
					<h4 className="categoryTitle">Todas</h4>
				</div>
				{_renderItemsCategories()}
			</>
		);
	};

	const manejarSubcategoriaNivel2 = (event) => {
		event.preventDefault();
		var attr = event.target.getAttribute('href');
		console.log(attr);
		if (attr !== null) {
			var newValue = 'link_' + attr.replace('#', '');
			setSubcategoria(newValue);

			var offsetTop = document.getElementById(attr.replace('#', ''));
			if (offsetTop) {
				// offsetTop.scrollIntoView({behavior: 'smooth'});
				document.querySelector('ion-content').scrollToPoint(0, (offsetTop.offsetTop - 80), 500);
			}
		}
	}

	const manejarSubcategoriaNivel3 = (event) => {
		event.preventDefault();
		var attr = event.target.getAttribute('href');
		var newValue = 'link_' + attr.replace('#', '');
		setSubsubcategoria(newValue);
		
		var offsetTop = document.getElementById(attr.replace('#', ''));
		if (offsetTop) {
			// offsetTop.scrollIntoView({behavior: 'smooth'});
			document.querySelector('ion-content').scrollToPoint(0, (offsetTop.offsetTop), 500);
		}
	}

	const passedDivs = [];

	const handleScroll = async (event) => {
		if (currentCategory !== null) {

			var section = document.querySelectorAll(".subcategoriaX");
			var sections = []; 
			var scroll = event.detail.scrollTop;
			var currentSubcategoria = subcategoria;

			Array.prototype.forEach.call(section, function(e) {
				sections.push(e.id);
			});

			// console.log(sections, scroll);
			var globalHeight = 0;
			sections.forEach((item, index) => {
				if (passedDivs.includes(item)) {
					return false;
				}

				let element = document.getElementById(item);
				let position = element.getBoundingClientRect();
				globalHeight += position.height
				
				if (position.top <= 90) {
					passedDivs.push(item);
					var nuevaSubcategoria = ('link_' + item);
					setSubcategoria(nuevaSubcategoria);
					var toParent = document.getElementById(nuevaSubcategoria);
					if (toParent) {
						toParent.parentNode.scrollTo({top: 0, left: toParent.offsetLeft, behavior: 'smooth'});
					}
				}
			})



			var section2 = document.querySelectorAll(".subsubcategoriaX");
			var sections2 = [];
			var scroll2 = event.detail.scrollTop;
			var currentSubcategoria2 = subsubcategoria;

			Array.prototype.forEach.call(section2, function(e) {
				sections2.push(e.id);
			});

			// console.log(sections, scroll);
			var globalHeight2 = 0;
			sections2.forEach(item => {
				let element = document.getElementById(item);
				let position = element.getBoundingClientRect();
				globalHeight2 += position.height

				// console.log(item, position, element.offsetHeight)
				
				// console.log(scroll, globalHeight, position.top)
				if (position.top <= 0 && scroll2 <= globalHeight2) {
					var nuevaSubcategoria = ('link_' + item);
					if (currentSubcategoria2 === null || currentSubcategoria2 !== nuevaSubcategoria) {
						setSubsubcategoria(nuevaSubcategoria);
						var toParent = document.getElementById(nuevaSubcategoria);
						if (toParent) {
							toParent.parentNode.scrollTo({top: 0, left: toParent.offsetLeft, behavior: 'smooth'});
						}
					}
				}
			})



			
			// for (i in sections) {
			// 	if (scroll > sections[i]) {
			// 		nuevaSubcategoria = ('link_' + i);
			// 		console.log('El state actual es ', currentSubcategoria);
			// 		console.log('El div es ', i);
			// 		if (currentSubcategoria === null || currentSubcategoria !== nuevaSubcategoria) {
			// 			setSubcategoria(nuevaSubcategoria);
			// 		}
			// 	}
			// }
		}
	}

	const _renderSubCategorias = () => {
		return (<>
			<div className="subCategorias1">
				{currentCategory.subcategorias.map(item => {
					return (
						<a onClick={manejarSubcategoriaNivel2} className={(subcategoria === ('link_subcategoria_' + (item.hasOwnProperty('id_category') ? item.id_category : item.id )) ? 'activo' : '')} id={'link_subcategoria_' + (item.hasOwnProperty('id_category') ? item.id_category : item.id )} href={"#subcategoria_" + (item.hasOwnProperty('id_category') ? item.id_category : item.id)} key={'subcategoria_' + (item.hasOwnProperty('id_category') ? item.id_category : item.id )}>{item.name}</a>
					)
				})}
			</div>
			{
				currentCategory.niveles === 3 ?
				(<>
					{currentCategory.hasOwnProperty('subcategorias') && currentCategory.subcategorias.map((item0, index) => (
						<>{item0.hasOwnProperty('subcategorias') ? <div id={'subcategoria_menu_' + item0.id_category} className={"subCategorias1 " + (subcategoria === ('link_subcategoria_' + item0.id_category) ? '' : 'hidden' )}>
							{item0.subcategorias.map(item => (
								<a onClick={manejarSubcategoriaNivel3} className={(subsubcategoria === ('link_subsubcategoria_' + item.id_category) ? 'activo' : '')} key={'subsubcategoria_l_' + item.id_category} id={'link_subsubcategoria_' + item.id_category} href={"#subsubcategoria_" + item.id_category}>{item.name}</a>
							))}
						</div> : null}</>
					))}
				</>)
				: null
			}
		</>)
	}

	const _renderSubcates = () => {
		return (
			<div className="Subcategorias0">
				{
					currentCategory !== null && currentCategory.hasOwnProperty('subcategorias') && currentCategory.subcategorias.length ?
					_renderSubCategorias() :
					null			
				}
			</div>
		)
	}
	
	const filterByValue = (array, string) => {
		return array.filter(o => {
			return Object.keys(o).some(k => {
				if (k === 'categories') {
					let verSiEsta = false;
					if (o[k] !== null && o[k]) {
						o[k].forEach(val => {
							var stringToCheck = val.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim();
							var stringSearch = string.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim();
							if (stringToCheck.includes(stringSearch)) {
								verSiEsta = true;
							}
						});
					}
					return verSiEsta;
				}
				return false;
			})
		});
	}

	const filterByValueAndParent = (array, string, parent) => {
		return array.filter(o => {
			return Object.keys(o).some(k => {
				if (k === 'categories') {
					let verSiEsta = false;
					if (o[k] !== null && o[k]) {
						if (Array.isArray(o[k])) {
							var newArray = [];
							o[k].forEach(val => {
								var stringToCheck = val.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim();
								newArray.push(stringToCheck);
							});
							var stringSearch = string.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim();
							var stringParent = parent.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim();
							if (newArray.includes(stringSearch) && newArray.includes(stringParent)) {
								verSiEsta = true;
							}
						} else {
							o[k].forEach(val => {
								var stringToCheck = val.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim();
								var stringSearch = string.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim();
								var stringParent = parent.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim();
								if (stringToCheck.includes(stringSearch) || stringToCheck.includes(stringParent)) {
									verSiEsta = true;
								}
							});
						}
					}
					return verSiEsta;
				}
				return false;
			})
		});
	}

	const filterByIdCategory = (array, string) => {
		return array.filter(o => {
			return Object.keys(o).some(k => {
				if (k === 'idcategories') {
					let verSiEsta = false;
					if (o[k] !== null) {
						if (o[k].includes(string)) {
							verSiEsta = true;
						}
					}
					return verSiEsta;
				}
				return false;
			})
		});
	}
	
	const filterByValueWords = (array, string) => {
		return array.filter(o => {
			return Object.keys(o).some(k => {
				if (k !== 'categories' && typeof o[k] === 'string') {
					let verSiEsta = false;
					// console.log(o, k)
					if (o[k].toLowerCase().trim().includes(string.toLowerCase().trim())) {
						verSiEsta = true;
					}
					return verSiEsta;
				}
				return false;
			})
		});
	}
	
	const orderByValue = (array, orderBy) => {
		let ret;
		switch (orderBy) {
			case 'nombre':
				ret = array.sort((a, b) => {
					let comparison = 0;
					if (a.name.toLowerCase() > b.name.toLowerCase()) {
						comparison = 1;
					} else if (a.name.toLowerCase() < b.name.toLowerCase()){
						comparison = -1;
					}
					return comparison;
				});
				break;
			case 'menoramayor': 
				ret = array.sort((a, b) => {
					let comparison = 0;
					if (parseInt(a.price) > parseInt(b.price)) {
						comparison = 1;
					} else if (parseInt(a.price) < parseInt(b.price)){
						comparison = -1;
					}
					return comparison;
				});
				break;
			case 'mayoramenor':
				ret = array.sort((a, b) => {
					let comparison = 0;
					if (parseInt(a.price) > parseInt(b.price)) {
						comparison = 1;
					} else if (parseInt(a.price) < parseInt(b.price)){
						comparison = -1;
					}
					return (comparison * -1);
				});
				break;
			case 'fecha':
				ret = array.sort((a, b) => {
					let comparison = 0;
					if (parseInt(a.created) > parseInt(b.created)) {
						comparison = 1;
					} else if (parseInt(a.created) < parseInt(b.created)){
						comparison = -1;
					}
					return comparison;
				});
				break;
			default:
				ret = array.sort((a, b) => {
					let comparison = 0;
					if (a.hasOwnProperty('order') && b.hasOwnProperty('order')) {
						comparison = a.order - b.order;
					}
					return comparison;
				});
				break;
		}
		
		return ret;
	}
	
	
	/* const VirtualScrollChild = ({children}) => {
		const [ref, inView] = useInView();
		return (
			<div className="cuadrosFinales nuevocuadro" ref={ref}>
				{inView ? children : null}
			</div>
		);
	} */

	const _renderItemProducts = () => {
		if (info.productos) {
			let productosToRead = [...info.productos];

			if (currentCategory !== null && currentCategory.hasOwnProperty('niveles')) {
				if (currentCategory.niveles) {
					console.log('LL 0')

					const nuevaCurrentCategory = {...currentCategory};
					if (nuevaCurrentCategory.subcategorias.length){
						console.log('LL 3')
						nuevaCurrentCategory.subcategorias.forEach((item, i) => {
							if (item.hasOwnProperty('subcategorias')) {
								item.subcategorias.forEach((item2, index) => {
									var newProducts = [...info.productos];
									newProducts = filterByIdCategory(newProducts, item2.id_category);
									
									if (buscar !== null) {
										newProducts = filterByValueWords(newProducts, buscar);
									}
									newProducts = orderByValue(newProducts, order);
									if (newProducts.length) {
										nuevaCurrentCategory.subcategorias[i].subcategorias[index].products = newProducts;
									}
								})
							} else {
								// console.log(item)
								// Tiene dos niveles así que aquí mismo se buscarán los productos
								if (item.hasOwnProperty('id_category')) {
									var newProducts = [...info.productos];
									newProducts = filterByIdCategory(newProducts, item.id_category);
									// console.log(newProducts)
									if (buscar !== null) {
										newProducts = filterByValueWords(newProducts, buscar);
									}
									newProducts = orderByValue(newProducts, order);
									// console.log(newProducts)
									if (newProducts.length) {
										nuevaCurrentCategory.subcategorias[i].products = newProducts;
									}
								} else {
									// AQUI SE DEBE BUSCAR NORMAL
									var newProducts = [...info.productos];
									newProducts = filterByValueAndParent(newProducts, item.name, nuevaCurrentCategory.name);
									if (buscar !== null) {
										newProducts = filterByValueWords(newProducts, buscar);
									}
									newProducts = orderByValue(newProducts, order);
									// console.log(newProducts)
									if (newProducts.length) {
										nuevaCurrentCategory.subcategorias[i].products = newProducts;
									}
								}
							}
						})
						productosToRead = nuevaCurrentCategory;
					} else {
						console.log('LL 1')
						productosToRead = filterByValue(productosToRead, currentCategory.name);
						if (buscar !== null) {
							productosToRead = filterByValueWords(productosToRead, buscar);
						}
						productosToRead = orderByValue(productosToRead, order);	
					}
					// console.log(productosToRead)
				}
			} else if (currentCategory !== null && !currentCategory.hasOwnProperty('niveles')) {
				productosToRead = filterByValue(productosToRead, currentCategory.name);
				if (buscar !== null) {
					productosToRead = filterByValueWords(productosToRead, buscar);
				}
				productosToRead = orderByValue(productosToRead, order);
			} else {
				if (buscar !== null) {
					productosToRead = filterByValueWords(productosToRead, buscar);
				}
				productosToRead = orderByValue(productosToRead, order);
			}

			if (!info.cargando) {
				if (currentCategory !== null && currentCategory.hasOwnProperty('niveles')) {
					if(currentCategory.niveles === 3) {
						return productosToRead.subcategorias.map(item => {
							return (
								<div className="nuevoProductoContainer">
									{(item.hasOwnProperty('subcategorias') || item.hasOwnProperty('products')) ? 
										(
											<div id={"subcategoria_" + item.id_category} className="subcategoriaX">
												<h2>{item.name}</h2>
												{item.subcategorias.map(item2 => {
													if (item2.hasOwnProperty('products') && item2.products.length) {
														return (
															<div id={"subsubcategoria_" + item2.id_category} className="subsubcategoriaX">
																<h3>{item2.name}</h3>
																<div className={columnas + ' productosPrincipal'}>
																	{item2.products.map((value) => {
																		return (
																			<Link className="nuevocuadro" to={"/product/" + value.id} key={value.id+'_1'}>
																				<div className="nuevocuadrocont">
																					<div className="imgcontainer">
																						{(value.cover && typeof value.cover === 'string') ? (<IonImg src={RetrieveImage(value.cover)} alt="Cover" />) : ((value.cover && typeof value.cover === 'object' && value.cover.length) ? (<IonImg src={RetrieveImage(value.cover[0])} alt="Cover" />) : '')}
																					</div>
																					<div className="inforproduct 5">
																						{(visible.includes('titulo')) ? (<h4>{value.name}</h4>) : ''}
																						<h5>
																							{(visible.includes('precio')) ? <span>$ {returnPrice(value.price)}</span> : ''}
																							{visible.includes('preciodescuento') ? ((parseInt(value.originalPrice) > parseInt(value.price)) ? (<span className="descuento 2">$ {returnPrice(parseInt(value.originalPrice))}</span>) : '') : ''}
																						</h5>
																						{(visible.includes('addtocart')) ? (<span className="addToCart"><IonIcon icon={bagHandleOutline} /> Agregar</span>) : ''}
																					</div>
																				</div>
																			</Link>
																		);
																	})}
																</div>
															</div>
														)
													} else {
														return (
														// 	{/* <div id={"subsubcategoria_" + item2.id_category} className="subsubcategoriaX">
														// 		<h3>{item2.name}</h3>
														// 		<div className="alerta">No se han encontrado productos</div>
														// </div> */}
															<style>{`
																#link_subsubcategoria_${item2.id_category} {
																	display: none;
																}
															`}</style>
														)
													}
												})}
											</div>
										)
										: (
											// <div id={"subcategoria_" + item.id_category} className="subcategoriaX">
											// 	<h2>{item.name}</h2>
											// 	<div className="alerta grande">No se han encontrado productos</div>
											// </div>
											<style>{`
												#link_subcategoria_${item.id_category} {
													display: none;
												}
											`}</style>
										)
									}
								</div>
							)
						})
					} else if (currentCategory.niveles === 2 && productosToRead.hasOwnProperty('subcategorias') && productosToRead.subcategorias.length) {
						return productosToRead.subcategorias.map(item => {
							return (
								<div className="nuevoProductoContainer" key={'nivel2_' + (item.hasOwnProperty('id_category') ? item.id_category : item.id )}>
									{(item.hasOwnProperty('products')) ? 
										(
											<div id={"subcategoria_" + (item.hasOwnProperty('id_category') ? item.id_category : item.id )} className="subcategoriaX">
												<h2>{item.name}</h2>
												<div className={columnas + ' productosPrincipal'}>
													{item.products.map((value) => {
														return (
															<Link className="nuevocuadro" to={"/product/" + value.id} key={value.id+'_1'}>
																<div className="nuevocuadrocont">
																	<div className="imgcontainer">
																		{(value.cover && typeof value.cover === 'string') ? (<IonImg src={RetrieveImage(value.cover)} alt="Cover" />) : ((value.cover && typeof value.cover === 'object' && value.cover.length) ? (<IonImg src={RetrieveImage(value.cover[0])} alt="Cover" />) : '')}
																	</div>
																	<div className="inforproduct 2">
																		{(visible.includes('titulo')) ? (<h4>{value.name}</h4>) : ''}
																		<h5>
																			{(visible.includes('precio')) ? <span>$ {returnPrice(value.price)}</span> : ''}
																			{visible.includes('preciodescuento') ? ((parseInt(value.originalPrice) > parseInt(value.price)) ? (<span className="descuento 1">$ {returnPrice(parseInt(value.originalPrice))}</span>) : '') : ''}
																		</h5>
																		{(visible.includes('addtocart')) ? (<span className="addToCart"><IonIcon icon={bagHandleOutline} /> Agregar</span>) : ''}
																	</div>
																</div>
															</Link>
														);
													})}
												</div>
											</div>
										)
										: (
											// <div id={"subcategoria_" + item.id_category} className="subcategoriaX">
											// 	<h2>{item.name}</h2>
											// 	<div className="alerta grande">No se han encontrado productos</div>
											// </div>
											<style>{`
												#link_subcategoria_${item.id_category} {
													display: none;
												}
											`}</style>
										)
									}
								</div>
							)
						})
					} else {
						return (
							<div className={columnas + ' productosPrincipal'}>
								{productosToRead.map((value) => {
									// console.log(value)
									return (
										<Link className="nuevocuadro" to={"/product/" + value.id} key={value.id+'_1'}>
											<div className="nuevocuadrocont">
												<div className="imgcontainer">
													{(value.cover && typeof value.cover === 'string') ? (<IonImg src={RetrieveImage(value.cover)} alt="Cover" />) : ((value.cover && typeof value.cover === 'object' && value.cover.length) ? (<IonImg src={RetrieveImage(value.cover[0])} alt="Cover" />) : '')}
												</div>
												<div className="inforproduct 1">
													{(visible.includes('titulo')) ? (<h4>{value.name}</h4>) : ''}
													<h5>
														{(visible.includes('precio')) ? <span>$ {returnPrice(parseFloat(value.price))}</span> : ''}
														{visible.includes('preciodescuento') ? ((parseInt(value.originalPrice) > parseInt(value.price)) ? (<span className="descuento 3">$ {returnPrice(parseInt(value.originalPrice))}</span>) : '') : ''}
													</h5>
													{(visible.includes('addtocart')) ? (<span className="addToCart"><IonIcon icon={bagHandleOutline} /> Agregar</span>) : ''}
												</div>
											</div>
										</Link>
									);
								})}
							</div>
						)
					}
				} else {
					return (
						<div className={columnas + ' productosPrincipal'}>
							{productosToRead.map((value) => {
								// console.log(value)
								return (
									<Link className="nuevocuadro" to={"/product/" + value.id} key={value.id+'_1'}>
										<div className="nuevocuadrocont">
											<div className="imgcontainer">
												{(value.cover && typeof value.cover === 'string') ? (<IonImg src={RetrieveImage(value.cover)} alt="Cover" />) : ((value.cover && typeof value.cover === 'object' && value.cover.length) ? (<IonImg src={RetrieveImage(value.cover[0])} alt="Cover" />) : '')}
											</div>
											<div className="inforproduct 3">
												{(visible.includes('titulo')) ? (<h4>{value.name}</h4>) : ''}
												{visible.includes('precio') ? (<h5>
													{(visible.includes('precio')) ? <span>$ {returnPrice(parseFloat(value.price))}</span> : ''}
													{visible.includes('preciodescuento') ? ((parseInt(value.originalPrice) > parseInt(value.price)) ? (<span className="descuento 3">$ {returnPrice(parseInt(value.originalPrice))}</span>) : '') : ''}
												</h5>) : null}
												{(visible.includes('descripcion')) ? (<Typography className={'descriptionText homepage'} dangerouslySetInnerHTML={{ __html: value.description.split('.')[0].replace(/(?:\r\n|\r|\n)/g, '<br>') }}></Typography>) : ''}
												{(visible.includes('addtocart')) ? (<span className="addToCart"><IonIcon icon={bagHandleOutline} /> Agregar</span>) : ''}
											</div>
										</div>
									</Link>
								);
							})}
						</div>
					)
				}
			} else {
				const examples = [];
				for (let i = 0; i <= 6; i++) {
					examples.push(
						<div key={i} className="nuevocuadro">
							<div className="nuevocuadrocont">
								<div className="imgcontainer">
									<IonSkeletonText animated style={{marginTop:'0', width:'100%', height: '100%', position: 'absolute'}} />
								</div>
								<div className="inforproduct 4">
									<IonSkeletonText animated style={{width:'100%',height:'24px', marginTop:'10px'}} />
									<div style={{display:'flex', justifyContent:'flex-start', marginBottom: '10px'}}>
										<IonSkeletonText animated style={{width:'60%', height: '16px'}} />
										<IonSkeletonText animated style={{width:'30%', height: '16px', marginLeft: '2%'}} />
									</div>
								</div>
							</div>
						</div>
					);
				}
				
				return examples;
			}
		} else {
			return (
				<div class="nohayproductos">
					De momento no hay productos disponibles en esta tienda
				</div>
			);
		}
	}

	const handlePhoneShare = async () => {
		var images = Array.from(document.querySelectorAll('.slick-slide:not(.slick-cloned) img'));
		var filesArray = [];
		for (let i in images) {
			// console.log(i);
			var image = images[i];
			var imagePreBlob = await fetch(image.src);
			var imageBlob = await imagePreBlob.blob();
			var file = new File([imageBlob], "picture.jpg", {type: 'image/jpeg'});
			filesArray.push(file);
		}
		// console.log(filesArray);
		if (navigator.share) {
		  navigator
			.share({
			  title: 'Catálogo de ' + ((info.tienda && info.tienda.nombreempresa) ? info.tienda.nombreempresa : info.subdomain),
			  text: `Disfruta de todos los artículos que tenemos para ti en ` + ((info.tienda && info.tienda.nombreempresa) ? info.tienda.nombreempresa : info.subdomain),
			  url: document.location.href,
			})
		} else {
			alert('Este navegador no soporta esta función, Cod 001');
		}
	}
	
	return (
		<IonPage>
			<style>{((info.tienda && info.tienda.hasOwnProperty('paleta') && info.tienda.paleta.length && info.tienda.paleta[0]) ? `
				.botonColumnas svg .a {
					stroke: ${info.tienda.paleta[0]};
				}
			` : [])}
			{`.botonColumnas.${botonActivo} {
				border: 2px solid #f0f0f0;
			}
			 .svgCircle {
				 stroke: `+ ((info.tienda && info.tienda.hasOwnProperty('paleta') && info.tienda.paleta.length && info.tienda.paleta[0]) ? `${info.tienda.paleta[0]} !important` : 'gray !important`') +`
			 }
			 .addToCart {
				 background: `+((info.tienda && info.tienda.hasOwnProperty('paleta') && info.tienda.paleta.length && info.tienda.paleta[0]) ? `${info.tienda.paleta[0]}` : 'gray')+`;
			 }
			 
			 ion-radio {
				--color-checked: `+((info.tienda && info.tienda.hasOwnProperty('paleta') && info.tienda.paleta.length && info.tienda.paleta[0]) ? `${info.tienda.paleta[0]}` : 'gray')+`;
			}
			.subCategorias1 > a:before {
				background-color: `+((info.tienda && info.tienda.hasOwnProperty('paleta') && info.tienda.paleta.length && info.tienda.paleta[0]) ? `${info.tienda.paleta[0]}` : 'gray')+`
			}

			.fixedReal ion-fab-button {
				--background: `+ ((info.tienda && info.tienda.hasOwnProperty('paleta') && info.tienda.paleta.length && info.tienda.paleta[0]) ? `${info.tienda.paleta[0]}` : 'gray') +` 
			}

			ion-radio {
				--color: `+ ((info.tienda && info.tienda.hasOwnProperty('paleta') && info.tienda.paleta.length && info.tienda.paleta[0]) ? `${info.tienda.paleta[0]}` : 'gray') +`;
			}

			ion-checkbox {
				--border-color: `+ ((info.tienda && info.tienda.hasOwnProperty('paleta') && info.tienda.paleta.length && info.tienda.paleta[0]) ? `${info.tienda.paleta[0]}` : 'gray') +`;
				--border-color-checked: `+ ((info.tienda && info.tienda.hasOwnProperty('paleta') && info.tienda.paleta.length && info.tienda.paleta[0]) ? `${info.tienda.paleta[0]}` : 'gray') +`;
				--background-checked: `+ ((info.tienda && info.tienda.hasOwnProperty('paleta') && info.tienda.paleta.length && info.tienda.paleta[0]) ? `${info.tienda.paleta[0]}` : 'gray') +`;
			}
			`}
			</style>
			
			<ProductosPage
				showProductDetail={showProductModal}
				tienda={info.tienda}
				producto={productToShow}
				openCart={setShowCartModal}
			/>
			
			<CartPage
				showCarritoModal={showCartModal}
				tienda={info.tienda}
				carrito={carritoToModal}
			/>
			
			<OrderPage
				showPedidoModal={showOrderModal}
				tienda={info.tienda}
				carrito={carritoToModal}
			/>
			
			<RevisionPage
				tienda={info.tienda}
				showRevisionModal={showRevisionModal}
			/>
			
			<IonContent
				ref={contentScroll}
				scrollEvents={true}
				onIonScroll={handleScroll}
				
			>
				<div className="fixedReal">
					<section className="">
						<Link to={"/cart"} className="cartButton">
							<IonIcon icon={bagHandleOutline} />
							<span className="overexposed">{totalCarrito}</span>
						</Link>
					</section>
					<section className="">
						<IonFab mode="ios" vertical="top" horizontal="start" slot="fixed">
							<IonFabButton mode="ios">
							<IonIcon icon={shareSocialOutline} />
							</IonFabButton>
							<IonFabList side="bottom" mode="ios">
							<IonFabButton>
								<WhatsappShareButton
									url={document.location.href}
									title={(info.tienda && info.tienda.nombreempresa) ? info.tienda.nombreempresa : info.subdomain}
									separator=":: "
									className="Demo__some-network__share-button"
									>
									<IonIcon icon={logoWhatsapp} />
								</WhatsappShareButton>
							</IonFabButton>
							<IonFabButton>
								<FacebookShareButton
									url={document.location.href}
									title={(info.tienda && info.tienda.nombreempresa) ? info.tienda.nombreempresa : info.subdomain}
									separator=":: "
									className="Demo__some-network__share-button"
									>
									<IonIcon icon={logoFacebook} />
								</FacebookShareButton>
							</IonFabButton>
							<IonFabButton>
								<TelegramShareButton
									url={document.location.href}
									title={(info.tienda && info.tienda.nombreempresa) ? info.tienda.nombreempresa : info.subdomain}
									separator=":: "
									className="Demo__some-network__share-button"
									>
									<TelegramIcon />
								</TelegramShareButton>
							</IonFabButton>
							<IonFabButton onClick={() => { handlePhoneShare() }}><IonIcon icon={phonePortraitOutline} /></IonFabButton>
							</IonFabList>
						</IonFab>
					</section>
				</div>
				<div id="realUploaderBack">
					<div id="cuadroPortada"  style={(info.tienda && info.tienda.hasOwnProperty('paleta') && info.tienda.paleta.length && info.tienda.paleta[0]) ? {borderColor: info.tienda.paleta[0]} : {}} className="botonportada">
						{info.cargando ? 
							<IonSkeletonText style={{width:'100%',height:'150px',marginTop:'0'}} animated /> :
							<IonImg id="portadaActual" src={RetrieveImage(info.tienda.portada_base, ((window.innerWidth > 600 ? 600 : window.innerWidth) + 'x150'))} className='portadaActual' />
						}
					</div>
					<div id="segundaLinea">
						<div id="TiendaWhatsContainer">
							<div id="TiendaWhats" style={(info.tienda && info.tienda.hasOwnProperty('paleta') && info.tienda.paleta.length && info.tienda.paleta[0]) ? {backgroundColor: info.tienda.paleta[0]} : {}}>
								Tienda Whatsapp
							</div>
						</div>
						<div className="logoUploaderContainer">
							<div className="logoUploader" style={(info.tienda && info.tienda.hasOwnProperty('paleta') && info.tienda.paleta.length && info.tienda.paleta[0]) ? {borderColor: info.tienda.paleta[0]} : {}}>
								{info.cargando ? 
									<IonSkeletonText animated style={{width:'100%',height:'100%',marginTop:'0'}} /> :
									<IonImg src={RetrieveImage(info.tienda.logo_url, '150x150')} className='logoActual' />
								}
							</div>
						</div>
						<div id="iconosDerecha">
							<a href="https://micatalogovirtual.com/" className="micatalogoButton" target="_blank" rel="noopener noreferrer"><MicatalogoIcon /></a>
							<a target="_blank" rel="noopener noreferrer" href={"//wa.me/" + lineaToUse + '?text=Hola, quiero más información de sus productos'}><span className="whatsappButtonGlobal"><IonIcon icon={logoWhatsapp} /></span></a>
						</div>
					</div>
				</div>
				<div id="userTiendaInfo">					
					<h1 className="tituloPrincipal">
						{(info.tienda && info.tienda.nombreempresa) ? info.tienda.nombreempresa : info.subdomain}
					</h1>
				</div>
				<div id="InfoCategories">
					<div className="totales">
						<h3>Productos</h3>
						<p>{(info.productos && info.productos.length) ? info.productos.length : '0'}</p>
					</div>
					<div className="totales buscador">
						<div className="buscadorContainer">
							<IonIcon icon={searchOutline} />
							<input onInput={(e) => {setBuscar(e.target.value)}} type="text" placeholder="Nombre, referencia o titulo" className="inputBuscador" />
						</div>
					</div>
					<div className="totales">
						<IonItem>
							<IonLabel><IonIcon icon={funnelOutline} /></IonLabel>
							<IonSelect interface="popover" okText="Filtrar" cancelText="Cancelar"  onIonChange={e => setOrder(e.detail.value)}>
								  <IonSelectOption value="nombre">Ordenar por nombre</IonSelectOption>
								  <IonSelectOption value="menoramayor">Precio: Menor a mayor</IonSelectOption>
								  <IonSelectOption value="mayoramenor">Precio: Mayor a menos</IonSelectOption>
								  <IonSelectOption value="fecha">Fecha de publicación</IonSelectOption>
								  <IonSelectOption value="limpiar">Limpiar</IonSelectOption>
							</IonSelect>
						</IonItem>
					</div>
				</div>
				<div className="filtroColumnas">
					<div className="columnaField">
						<IonButton onClick={() => { setColumnas('una'); setBotonActivo('una'); }} className="botonColumnas una" dataColumnas="onecolumn">
							<ColumnIcon />
						</IonButton>
					</div>
					<div className="columnaField">
						<IonButton onClick={() => { setColumnas('dos'); setBotonActivo('dos'); }} className="botonColumnas dos" dataColumnas="twocolumns">
							<ColumnIcon /><ColumnIcon />
						</IonButton>
					</div>
					<div className="columnaField">
						<IonButton onClick={() => { setColumnas('tres'); setBotonActivo('tres'); }} className="botonColumnas tres" dataColumnas="threecolumns">
							<ColumnIcon /><ColumnIcon /><ColumnIcon />
						</IonButton>
					</div>
				</div>
				<div className="categoriesContPrincipal">
					<div className="categoriesContainer">
						{_renderList()}
					</div>
				</div>
				{_renderSubcates()}
				{_renderItemProducts()}
			</IonContent>
			{((info.tienda && info.tienda.nombreempresa !== '') ?
							<PWAPrompt timesToShow={3} promptOnVisit={1} copyTitle={'Añadir a pantalla de inicio'} copyBody={'Esta página tiene una versión de aplicativo para que puedas utilizarla directamente en tu teléfono'} copyShareButtonLabel={"Presiona el botón 'Compartir'"} copyAddHomeButtonLabel={"Presiona 'Agregar a Inicio'"} copyClosePrompt={'Cancelar'} />
							: '')}
		</IonPage>
	);
};

export default HomePage;
