import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonIcon,
  IonModal,
  IonSkeletonText,
  IonSelect,
  IonSelectOption,
  IonFab,
  IonFabButton,
  IonFabList,
  IonRippleEffect,
  IonButton,
  IonRadio,
  IonRadioGroup,
  IonItem,
  IonLabel,
  IonCheckbox
} from "@ionic/react";

import {
	WhatsappShareButton,
	FacebookShareButton,
	TelegramShareButton
} from "react-share";

import Slider from "react-slick";
import { logoWhatsapp, arrowBackOutline, bagHandleOutline, shareSocialOutline, cloudDownloadOutline, phonePortraitOutline, logoFacebook, removeOutline, addOutline } from 'ionicons/icons';
import TelegramIcon from '@material-ui/icons/Telegram';
import RetrieveImage from './../retrieveImg';
import { Typography } from '@material-ui/core';
import * as cartService from "./../cart";
import md5 from 'md5';
import { returnPrice } from '../utils';


import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const AdicionesSingulares = (props) => {
	const { info, adicionAgregada } = props;
	const [curItem, setCurItem] = React.useState(null)
	
	React.useEffect(() => {
		if (curItem !== null) {
			var it = info.adiciones[curItem];
			adicionAgregada({index: curItem, item: it});
		}
	}, [curItem])

	return (
		<div>
			<IonRadioGroup onIonChange={e => { setCurItem(e.target.value) }}>
				{info.adiciones.map((item, index) => (
					<IonItem className="itemOpciones" key={item.adicion}>
						<IonLabel>
							<span className={"labelTag" + (item.extraPrice < 1 ? ' tienePrecioExtra' : '')}>{item.adicion}</span>
							{item.extraPrice > 0 ? <small className="extraPrice">+ ${returnPrice(item.extraPrice)}</small> : null}
						</IonLabel>
						<IonRadio slot="end" mode="md" value={index} />
					</IonItem>
				))}
			</IonRadioGroup>
		</div>
	)
}

const AdicionesMultiples = (props) => {
	const { info, adicionAgregada } = props;
	const [elegidas, setElegidas] = React.useState({});
	const [disabled, setDisabled] = React.useState(false);

	React.useEffect(() => {
		if (Object.keys(elegidas).length) {
			adicionAgregada(elegidas);
		}
	}, [elegidas]);

	const handleMultipleAdiciones = (checked, value) => {
		const curElegidas = {...elegidas};
		if (checked) {
			curElegidas[value.index] = value;
		} else {
			delete curElegidas[value.index];
		}
		setElegidas(curElegidas)
		
		if (info.maximoPermitido <= Object.keys(curElegidas).length) {
			setDisabled(true);
		} else {
			setDisabled(false);
		}
	}

	const defineIfDisabled = (index) => {
		return disabled && !elegidas.hasOwnProperty(index);
	}

	return (
		<div>
			{info.adiciones.map((item, index) => (
				<IonItem className="itemOpciones" key={index + item.adicion}>
					<IonLabel>
						<span className={"labelTag" + (item.extraPrice < 1 ? ' tienePrecioExtra' : '')}>{item.adicion}</span>
						{item.extraPrice > 0 ? <small className="extraPrice">+ ${returnPrice(item.extraPrice)}</small> : null}
					</IonLabel>
					<IonCheckbox disabled={defineIfDisabled(index)} slot="end" mode="md" onIonChange={e => { handleMultipleAdiciones(e.detail.checked, e.target.value) }} value={{index, item}} />
				</IonItem>
			))}
		</div>
	)
}


const AdicionesCantidad = (props) => {
	const { info, adicionAgregada } = props;
	const [selected, setSelected] = React.useState({});
	const [disabled, setDisabled] = React.useState(false);

	React.useEffect(() => {
		if (Object.keys(selected).length) {
			adicionAgregada(selected);
		}
	}, [selected])

	const sumarCantidad = (value) => {
		const curSelected = {...selected};
		if (!curSelected.hasOwnProperty(value.index)) {
			value.item.quantity = 1;
			curSelected[value.index] = value;
		} else {
			curSelected[value.index].item.quantity += 1;
		}

		validateFields(curSelected);
	}

	const restarCantidad = (value) => {
		const curSelected = {...selected};
		if (curSelected[value.index].item.quantity === 1) {
			delete curSelected[value.index];
		} else {
			curSelected[value.index].item.quantity -= 1;
		}

		validateFields(curSelected);
	}

	const validateFields = (curSelected) => {
		setSelected(curSelected);
		var maximo = info.maximoPermitido;
		var totalHastaAhora = 0;
		Object.values(curSelected).forEach(item => {
			totalHastaAhora += item.item.quantity;
		})
		if (totalHastaAhora >= maximo) {
			setDisabled(true);
		} else {
			setDisabled(false);
		}

	}

	return (
		<div>
			{info.adiciones.map((item, index) => (
				<IonItem className="itemOpciones" key={item.adicion}>
					<IonLabel>
						<span className={"labelTag" + (item.extraPrice < 1 ? ' tienePrecioExtra' : '')}>{item.adicion}</span>
						{item.extraPrice > 0 ? <small className="extraPrice">+ ${returnPrice(item.extraPrice)}</small> : null}
					</IonLabel>
					<div slot="end">
						{Object.keys(selected).length && selected.hasOwnProperty(index) ? 
						<div className="formQuantityAdicion">
							<button type="button" className="iconToUp ion-activatable ripple-parent" onClick={() => { restarCantidad({index, item}) }}>
								<IonIcon icon={removeOutline} />
								<IonRippleEffect></IonRippleEffect>
							</button>
							<span className="quantityInfo">{selected[index].item.quantity}</span>
							<button type="button" disabled={disabled} className="iconToUp ion-activatable ripple-parent" onClick={() => { sumarCantidad({index, item}) }}>
								<IonIcon icon={addOutline} />
								<IonRippleEffect></IonRippleEffect>
							</button>
						</div> :
						<div className="formQuantityAdicion">
							<button type="button" disabled={disabled} className="iconToUp ion-activatable ripple-parent" onClick={() => { sumarCantidad({index, item}) }}>
								<IonIcon icon={addOutline} />
								<IonRippleEffect></IonRippleEffect>
							</button>
						</div>
						}
					</div>
				</IonItem>
			))}
		</div>
	)
}



const ProductosPage = ({showProductDetail, tienda, producto, openCart}) => {
	const history = useHistory();
	
	const [carrito, setCarrito] = useState(0);
	const [product, setProduct] = useState({
		cover: '',
		name: '',
		price: 0,
		originalPrice: 0,
		description: '',
		quantity: 0,
		adiciones: [],
		combinations: [],
		id: '',
		reference: '',
	});
	const [mostrarMas, setMostrarMas] = useState(false);
	const [curCombi, setCurCombi] = useState({});
	const [notaproducto, setNotaProducto] = useState('');
	const [aprobaciones, setAprobaciones] = useState(0);
	const [adicionesSeleccionadas, setAdicionesSeleccionadas] = useState({});
	const [extraPrice, setExtraPrice] = useState(0);
	
	const handleInputRadio = (e) => {
		const currentInput = {...curCombi};
		currentInput[e.target.name] = e.target.value;
		setCurCombi(currentInput);

		var finalComb = [];
		for (let i in currentInput) {
			finalComb.push(currentInput[i]);
		}
		const combination = findCombination(finalComb);
		const curProd = {...product};
		
		if (combination && combination.hasOwnProperty('price') && combination.price) {
			curProd['newPrice'] = combination.price;
		} else {
			delete curProd['newPrice'];
		}
		setProduct(curProd);
	}
	
	const _callOptions = (array) => {
		return array.map((att, indx) => {
			return (
					<IonSelectOption key={indx} value={att}>
						{att}
					</IonSelectOption>
				);
		})
	}

	const _countOptions = (array) => {
		var count = 0;
		array.map((att) => {
			count += att.length
			return true;
		})
		return count;
	}
	
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true
    };
	
	const renderImages = () => {
		const images = [...product.cover];
		return images.map((img, k) => {
			return (
				<img src={RetrieveImage(img)} key={k} alt={"imagen_" + k} />
			);
		});
	}
	const renderSlider = () => {
		return (
			<Slider {...settings}>
				{(product.cover && typeof product.cover === 'string') ? (<img alt="imagen_0" src={RetrieveImage(product.cover)} />) : renderImages()}
			</Slider>
		);
	}
	
	const _loadcombisifhave = () => {
		if (product.hasOwnProperty('combinations') && product.combinations.length) {
			let attributes = {};
			product.combinations.forEach((k, v) => {
				k.data.forEach((kk, vv) => {
					if (attributes[kk.attribute_group]) {
						if (!attributes[kk.attribute_group].includes(kk.value)) {
							attributes[kk.attribute_group].push(kk.value);
						}
					} else {
						attributes[kk.attribute_group] = [];
						attributes[kk.attribute_group].push(kk.value);
					}
				});
			});

			var collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});
			
			return Object.entries(attributes).sort(collator.compare).map((entry, indexx) => {
				return (entry[1].length) ? (
					<div className="attributesDisponibles" key={indexx}>
						<h4 className="attribute">{entry[0].charAt(0).toUpperCase() + entry[0].slice(1)}</h4>
						<div className="inputs">
							<div className="inputCirculos">
								{(entry[0] === 'colores' || entry[0].toLowerCase().includes('color') || (entry[1].length > 4 && _countOptions(entry[1]) > 35)) ? (
									<IonSelect okText="Elegir" cancelText="Cancelar" onIonChange={(e) => { handleInputRadio(e) }} name={'group_'+indexx} className='selectColor' placeholder={"Elegir " + entry[0].charAt(0).toUpperCase() + entry[0].slice(1)}>
										{_callOptions(entry[1])}
									</IonSelect>
								) : (
									<>
										{entry[1].map((att, indx) => {
											return (
													<div key={'attribute_'+indx} style={{textAlign: 'center'}} className={"botonactivado" + (att.length > 3 ? ' esmaslargo' : '')}>
														<input onInput={(event) => { handleInputRadio(event) }} name={'group_'+indexx} type="radio" value={att} />
														<label className={"boton_" + indx + " grupo_"+indexx}>{att}</label>
													</div>
												);
										})}
									</>
								)}
							</div>
						</div>
					</div>
				) : '';
			});
		} else {
			return '';
		}
	}

	const handleAdicionSingular = React.useCallback((data, grupo) => {
		const curAdiciones = {...adicionesSeleccionadas};

		data.item.quantity = 1;
		var scope = {};
		scope[data.index] = data.item;
		curAdiciones[grupo._id] = scope;
		
		setAdicionesSeleccionadas(curAdiciones);
	});

	const handleAdicionMultiple = React.useCallback((data, grupo) => {
		const curAdiciones = {...adicionesSeleccionadas};
		var scope = {};
		Object.values(data).forEach(item => {
			item.item.quantity = 1;
			scope[item.index] = item.item;
		})
		curAdiciones[grupo._id] = scope;
		setAdicionesSeleccionadas(curAdiciones);
	})

	const handleAdicionCantidad = React.useCallback((data, grupo) => {
		const curAdiciones = {...adicionesSeleccionadas};
		var scope = {};
		Object.values(data).forEach(item => {
			scope[item.index] = item.item;
		})
		curAdiciones[grupo._id] = scope;
		setAdicionesSeleccionadas(curAdiciones);
	})

	const _loadadicionesifhave = () => {
		if (product.hasOwnProperty('adiciones') && product.adiciones.length) {
			return product.adiciones.filter(adicion => {
				var grupoCompleto = tienda.gruposadiciones[adicion._id];
				if (typeof grupoCompleto !== 'undefined') {
					adicion.adiciones = adicion.adiciones.filter(valor => {
						if (grupoCompleto.valores.includes(valor.adicion)) {
							return valor
						}
					})
				}
				return adicion;
			}).map(grupo => {
				return (
					<div className="attributesDisponibles" key={grupo._id}>
						<h4 className="attribute">
							<span>
								{grupo.tituloGrupo}
								{grupo.esObligatorio ? <span className="obligatorioLabel">Obligatorio</span> : null}
							</span>
							{grupo.tipoGrupo !== 'singular' ? <small>Elige máximo {grupo.maximoPermitido} {grupo.maximoPermitido > 1 ? 'opciones': 'opción'}</small> : null}
						</h4>
						<div className="inputs">
						{grupo.tipoGrupo === 'singular' ? (
								<AdicionesSingulares adicionAgregada={data => { handleAdicionSingular(data, grupo) }} info={grupo} />
							) : null}
							{grupo.tipoGrupo === 'multiple' ? (
								<AdicionesMultiples info={grupo} adicionAgregada={data => { handleAdicionMultiple(data, grupo) }} />
							) : null}
							{grupo.tipoGrupo === 'cantidad' ? (
								<AdicionesCantidad info={grupo} adicionAgregada={data => { handleAdicionCantidad(data, grupo) }} />
							) : null}
							
						</div>
					</div>
				)
			})
		} else {
			return '';
		}
	}
	
	const arraysEqual = (a, b) => {
	  if (a === b) return true;
	  if (a == null || b == null) return false;
	  if (a.length !== b.length) return false;
	  for (var i = 0; i < a.length; ++i) {
		if (a[i] !== b[i]) return false;
	  }
	  return true;
	}
	
	const s = (x,y) => {
		var pre = ['string' , 'number' , 'bool']
		if(typeof x!== typeof y )return pre.indexOf(typeof y) - pre.indexOf(typeof x);

		if(x === y)return 0;
		else return (x > y)?1:-1;

	}
	
	const findCombination = (finalComb) => {
		const prodCombis = [...product.combinations];
		var todasLasCombinaciones = {};
		for (let i in prodCombis) {
			var combs = prodCombis[i].data;
			todasLasCombinaciones[i] = [];
			combs.forEach((key, val) => {
				todasLasCombinaciones[i].push(key.value);
			});
		}
		var finalIndex = null;
		for (let j in todasLasCombinaciones) {
			if (arraysEqual(todasLasCombinaciones[j].sort(s), finalComb.sort(s))) {
				finalIndex = j;
			} 
		}
		
		return prodCombis[finalIndex];
	}

	const calcularExtra = (adiciones) => {
		var suma = 0;
		Object.values(adiciones).forEach(item => {
			Object.values(item).forEach(adicion => {
				suma += (adicion.extraPrice * adicion.quantity);
			})
		});

		// console.log(suma);
		return suma;
	}

	React.useEffect(() => {
		if (Object.keys(adicionesSeleccionadas).length) {
			setExtraPrice(calcularExtra(adicionesSeleccionadas));
		}
	}, [adicionesSeleccionadas]);

	const validarReglasGrupo = (item) => {

		if (!adicionesSeleccionadas.hasOwnProperty(item._id)) {
			return {
				aprobado: false,
				razon: 'Debe elegir al menos ' + (item.minimoPermitido > 1 ? (item.minimoPermitido + ' opciones') : 'una opción') + ' de ' + item.tituloGrupo
			}
		} else {
			if (item.minimoPermitido > Object.keys(adicionesSeleccionadas[item._id]).length) {
				var faltantes = (item.minimoPermitido - Object.keys(adicionesSeleccionadas[item._id]).length)
				return {
					aprobado: false,
					razon: 'Debe elegir ' + faltantes + (faltantes === 1 ? ' opción más' : ' opciones más') + ' del tipo ' + item.tituloGrupo
				}
			}
		}

		return {
			aprobado: true,
			razon: ''
		}
	}
	
	const handleSubmit = (event) => {
		event.preventDefault();
		if (product.hasOwnProperty('adiciones') && product.adiciones.length) {
			var paso = true;
			var razon = '';

			product.adiciones.forEach(item => {
				if (paso) {
					if (item.esObligatorio) {
						var revisar = validarReglasGrupo(item);
						if (!revisar.aprobado) {
							paso = false;
							razon = revisar.razon;
						}
					}
				}
			})

			if (!paso) {
				alert(razon);
				return false;
			}
		}

		if (product.hasOwnProperty('combinations') && product.combinations.length) {
			var finalComb = [];
			const combis = {...curCombi};
			for (let i in combis) {
				finalComb.push(combis[i]);
			}
			const combination = findCombination(finalComb);
			console.log(combination, curCombi)
			if (typeof combination === 'undefined') {
				alert('La combinación ingresada no cuenta con stock');
			} else {
				var quant = parseInt(combination.quantity);
				if (!quant) {
					alert('No hay stock disponible para la combinación seleccionada');
				} else {
					var objToSend = {};
					objToSend[product.id+'_'+finalComb.sort(s).join() + (notaproducto ? '_' + md5(notaproducto) : '') + (Object.keys(adicionesSeleccionadas).length ? (md5(JSON.stringify(adicionesSeleccionadas))) : '')] = {
						id: product.id,
						cover: product.cover,
						name: product.name,
						reference: (product.reference ? product.reference : 0),
						price: (parseFloat((product.hasOwnProperty('newPrice') ? product.newPrice : product.price)) + extraPrice),
						quantity: 0,
						adiciones: (Object.keys(adicionesSeleccionadas).length ? adicionesSeleccionadas : null),
						adicionesInfo: (product.hasOwnProperty('adiciones') ? product.adiciones : null),
						total: 0,
						stockAvailable: parseInt(combination.quantity),
						attributes: finalComb.join(' - '),
						notes: notaproducto
					}
					const result = cartService.addToCart(objToSend);
					if (result.msg !== 'OK') {
						alert(result.msg);
					} else {
						setCarrito(Object.keys(result.data).length);
						history.push('/cart');
					}
				}
			}
		} else {
			console.log(product)
			if (typeof product.quantity === 'string') {
				var tempQuantity = parseInt(product.quantity);
				if (isNaN(tempQuantity)) {
					tempQuantity = 1000000;
				}
				product.quantity = tempQuantity;
			}

			if (product.quantity) {
				var objToSend2 = {};
				objToSend2[product.id + (notaproducto ? '_' + md5(notaproducto) : '') + (Object.keys(adicionesSeleccionadas).length ? (md5(JSON.stringify(adicionesSeleccionadas))) : '')] = {
					id: product.id,
					cover: product.cover,
					name: product.name,
					reference: (product.reference ? product.reference : 0),
					price: (parseFloat(product.price) + extraPrice),
					quantity: 0,
					adiciones: (Object.keys(adicionesSeleccionadas).length ? adicionesSeleccionadas : null),
					adicionesInfo: (product.hasOwnProperty('adiciones') ? product.adiciones : null),
					total: 0,
					stockAvailable: parseInt(product.quantity),
					attributes: '',
					notes: notaproducto
				}
				const result = cartService.addToCart(objToSend2);
				if (result.msg !== 'OK') {
					alert(result.msg);
				} else {
					setCarrito(Object.keys(result.data).length);
					history.push('/cart');
				}
			} else {
				alert('No hay stock disponible para este producto');
			}
		}
	}
	
	useEffect(() => {
		if (producto) {
			(async () => {
				if (producto.adiciones && producto.adiciones) {
					var aprob = aprobaciones;
					producto.adiciones.forEach(item => {
						if (item.esObligatorio) {
							aprob++;
						}
					})
					// console.log(aprob);
					setAprobaciones(aprob);
				}


				if (producto.combinations && typeof producto.combinations === 'string') {
					producto.combinations = JSON.parse(producto.combinations);
				}
				const consulta = await fetch('https://micatalogovirtual.com/obtenerCombis.php?id='+ tienda.id +'&id_product=' + producto.id + '&token=' + Date.now()).catch(err => {
					console.log(err);
				})
				if (consulta) {
					const resultado = await consulta.json();
					if (resultado) {
						if (!resultado.hasOwnProperty('error')) {
							producto.combinations = resultado;
						}
					}
				}

				setProduct(producto);
				const cart = cartService.getCart();
				setCarrito(Object.keys(cart).length);
				setNotaProducto('');
				setCurCombi({});
			})();
		}
	}, [producto, tienda.id]);
	
	const handleCurrentImage = async () => {
		var url = document.querySelector('.slick-active img').src
		const blobPrev = await fetch(url);
		const blob = await blobPrev.blob();

		const urlF = URL.createObjectURL(blob);
		console.log(urlF);
		
		var link = document.createElement('a');
		link.href = urlF;
		link.download = product.name + '.jpg';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}
	
	const handlePhoneShare = async () => {
		var images = Array.from(document.querySelectorAll('.slick-slide:not(.slick-cloned) img'));
		var filesArray = [];
		for (let i in images) {
			// console.log(i);
			var image = images[i];
			var imagePreBlob = await fetch(image.src);
			var imageBlob = await imagePreBlob.blob();
			var file = new File([imageBlob], "picture.jpg", {type: 'image/jpeg'});
			filesArray.push(file);
		}
		// console.log(filesArray);
		if (navigator.share) {
		  navigator
			.share({
			  files: filesArray,
			  title: 'Catálogo de ' + (tienda && tienda.nombreempresa ? tienda.nombreempresa : 'Mi catalogo virtual'),
			  text: `Mira este producto de ` + (tienda && tienda.nombreempresa ? tienda.nombreempresa : 'Mi catálogo virtual') + `: ` + product.name,
			  url: document.location.href,
			})
		} else {
			alert('Este navegador no soporta esta función, Cod 001');
		}
	}

	const urlify = (text) => {
		// text = 'Hola que mas, esta es una descripción de prueba con un link que hace https://google.com y poco mas la verdad. También podría contener http://google.com o www.sitio.com'
		var urlRegex = /(https?:\/\/[^\s]+)/g;
		var replace1 = text.replace(urlRegex, function(url) {
		  return '<a class="linkGeneral" style="font-weight:bold;" href="' + url + '" target="_blank">' + url + '</a>';
		})

		var urlRegex2 = /(www?\.[^\s]+)/g;
		return replace1.replace(urlRegex2, function(url) {
			return '<a class="linkGeneral" style="font-weight:bold;" href="https://' + url + '" target="_blank">' + url + '</a>';
		})
		// or alternatively
		// return text.replace(urlRegex, '<a href="$1">$1</a>')
	}
	
	return (
		<IonModal mode="ios" isOpen={showProductDetail} onDidDismiss={() => { setProduct({
			cover: '',
			name: '',
			price: 0,
			originalPrice: 0,
			description: '',
			quantity: 0,
			combinations: [],
			id: '',
			reference: ''
		}); setNotaProducto(''); setCarrito(0); setMostrarMas(false); }}>
			<style>{((tienda && tienda.paleta && tienda.paleta[0]) ? `
				#submitForm {
					background: ${tienda.paleta[0]};
				}

				.linkGeneral {
					color: ${tienda.paleta[0]};
				}
				
				.botonactivado {
					border-color: ${tienda.paleta[0]};
					color: ${tienda.paleta[0]};
				}
				
				.selectColor {
					border-color: ${tienda.paleta[0]};
					color: ${tienda.paleta[0]};
				}
				
				.slick-dots li.slick-active button:before,
				.slick-dots li:hover button:before {
					color: ${tienda.paleta[0]} !important;
				}
				
				.alert-button-group * {
					color: ${tienda.paleta[0]};
				}
				
				.alert-radio-inner {
					border-color: ${tienda.paleta[0]} !important;
				}
				
				[aria-checked="true"] .alert-radio-label {
					color: ${tienda.paleta[0]} !important;
				}
				
				.contenedorSlider ion-fab-button {
					--background: ${tienda.paleta[0]} !important;
				}
				
				.whatsappButton {
					background: ${tienda.paleta[0]};
				}

				.ContainerForButtonViewMore ion-button {
					color: ${tienda.paleta[0]};
				}

				.textareaProducto {
					border-color: ${tienda.paleta[0]}
				}
			` : [])}
			</style>
			<IonHeader className="headerProd">
				<IonToolbar mode="ios">
					<IonButtons slot="start">
						<Link to={"/"} className="cartHeader">
							<IonIcon icon={arrowBackOutline} />
						</Link>
					</IonButtons>
					<div>
						{(tienda && tienda.hasOwnProperty('logo_url') && tienda.logo_url !== '') ? (<img alt="logo_mini" src={RetrieveImage(tienda.logo_url)} />) : (<IonSkeletonText animated style={{height:'30px', width: '30px'}} />)}
					</div>
					<IonButtons slot="end">
						<Link to={"/cart"} className="cartHeader">
							<IonIcon icon={bagHandleOutline} />
							<span className="overexposed">{carrito}</span>
						</Link>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<section className="productInfo">
					{(product.cover && product.cover !== '') ? 
						(<div className="contenedorSlider">
							<IonFab mode="ios" vertical="top" horizontal="end" slot="fixed">
							  <IonFabButton mode="ios">
								<IonIcon icon={shareSocialOutline} />
							  </IonFabButton>
							  <IonFabList side="bottom" mode="ios">
								<IonFabButton>
									<WhatsappShareButton
										url={document.location.href}
										title={product.name}
										separator=":: "
										className="Demo__some-network__share-button"
									  >
										<IonIcon icon={logoWhatsapp} />
									</WhatsappShareButton>
								</IonFabButton>
								<IonFabButton>
									<FacebookShareButton
										url={document.location.href}
										title={product.name}
										separator=":: "
										className="Demo__some-network__share-button"
									  >
										<IonIcon icon={logoFacebook} />
									</FacebookShareButton>
								</IonFabButton>
								<IonFabButton>
									<TelegramShareButton
										url={document.location.href}
										title={product.name}
										separator=":: "
										className="Demo__some-network__share-button"
									  >
										<TelegramIcon />
									</TelegramShareButton>
								</IonFabButton>
								<IonFabButton onClick={() => { handleCurrentImage() }}><IonIcon icon={cloudDownloadOutline} /></IonFabButton>
								<IonFabButton onClick={() => { handlePhoneShare() }}><IonIcon icon={phonePortraitOutline} /></IonFabButton>
							  </IonFabList>
							</IonFab>
							{renderSlider()}
						</div>)
					 : (<IonSkeletonText animated style={{width:'100%',height:'300px', marginTop: '0'}} />)}
					<div className="infoText">
						<h6 id="nombreProducto">{product.name}</h6>
						{tienda.hasOwnProperty('visibility') && tienda.visibility.includes('precio') ? <h4 id="productPrice">
							<span id="precioProducto">$ {returnPrice(  parseFloat((product.hasOwnProperty('newPrice') ? product.newPrice : product.price)) + extraPrice)} 
								{parseInt(product.originalPrice) > parseInt((product.hasOwnProperty('newPrice') ? product.newPrice : product.price)) ? (<small>$ {returnPrice(parseInt(product.originalPrice))}</small>) : ''}
							</span>
							{product.hasOwnProperty('reference') ? (<span className="referenceProduct" id="referenceProduct"><strong>Ref:</strong> {product.reference}</span>) : ''}
						</h4> : null}
						{
							(product.description.length > 315)
							? (
								<div className="ContainerForViewMore">
									<Typography className={'descriptionText' + (mostrarMas ? ' abierto' : '')} dangerouslySetInnerHTML={{ __html: urlify(product.description).replace(/(?:\r\n|\r|\n)/g, '<br>') }}></Typography>
									<div className="ContainerForButtonViewMore">
										<IonButton mode="ios" fill="clear" size="small" className="vermasButton" onClick={() => {
											const currentMostrarMas = mostrarMas;
											setMostrarMas(!currentMostrarMas);
										}}>{mostrarMas ? 'Ver menos' : 'Ver más'}</IonButton>
									</div>
								</div>
							)
							: (
								<Typography className="descriptionText sinlimite" dangerouslySetInnerHTML={{ __html: urlify(product.description).replace(/(?:\r\n|\r|\n)/g, '<br>') }}></Typography>
							)
						}
					</div>
					<form className="formCombis" onSubmit={(e) => { handleSubmit(e); }}>
						{_loadcombisifhave()}
						{_loadadicionesifhave()}
						<textarea className="textareaProducto" value={notaproducto} onChange={(e) => { setNotaProducto(e.target.value) }} placeholder="Dejar tus observaciones..."></textarea>
						<button type="submit" className={"whatsappButton cartfooterbutton ripple-parent ion-activatable "}>
							<IonIcon icon={logoWhatsapp} /> Agregar al pedido
							<IonRippleEffect></IonRippleEffect>
						</button>
						
					</form>
				</section>
			</IonContent>
		</IonModal>
	);
};

export default ProductosPage;
