export const getTienda = () => {
	const domain = window.location.host;
	const subdomain = domain.split('.')[0];
	return subdomain;
}

export const getCart = () => {
	let idtienda = getTienda();
	let json = localStorage.getItem(idtienda + '_cartv32');
	let ret = JSON.parse(json);
	if (ret === null) {
		ret = {};
	}
	return ret;
}

export const addToCart = (toAdd) => {
	let idtienda = getTienda();
	const curData = getCart();
	
	let datos = toAdd[Object.keys(toAdd)[0]];
	if (curData.hasOwnProperty(Object.keys(toAdd)[0])) {
		datos = curData[Object.keys(toAdd)[0]];
	}
	
	if (datos.stockAvailable !== 0) {
		datos.total += datos.price;
		datos.quantity += 1;
		datos.stockAvailable -= 1;
	} else {
		return {msg: 'No hay mas stock disponible para esta combinación'};
	}
	curData[Object.keys(toAdd)[0]] = datos;
	
	const jsonToSave = JSON.stringify(curData);
	localStorage.setItem(idtienda + '_cartv32', jsonToSave);
	
	return {msg: 'OK', data: curData};
}

export const upCart = (cart, index) => {
	let idtienda = getTienda();
	const curData = getCart();
	
	let datos = cart;
	
	if (datos.stockAvailable !== 0) {
		datos.total += datos.price;
		datos.quantity += 1;
		datos.stockAvailable -= 1;
	} else {
		return {msg: 'No hay mas stock disponible para esta combinación'};
	}
	
	curData[index] = datos;
	const jsonToSave = JSON.stringify(curData);
	localStorage.setItem(idtienda + '_cartv32', jsonToSave);
	
	return {msg: 'OK', data: curData};
}

export const downCart = (cart, index) => {
	let idtienda = getTienda();
	const curData = getCart();
	
	let datos = cart;
	
	if (datos.quantity > 1) {
		datos.total -= datos.price;
		datos.quantity -= 1;
		datos.stockAvailable += 1;
	} else if (datos.quantity === 1) {
		return borrarCart(index);
	}
	
	curData[index] = datos;
	const jsonToSave = JSON.stringify(curData);
	localStorage.setItem(idtienda + '_cartv32', jsonToSave);
	
	return {msg: 'OK', data: curData};
}

export const borrarCart = (index) => {
	let idtienda = getTienda();
	const curData = getCart();
	delete curData[index];
	
	const jsonToSave = JSON.stringify(curData);
	localStorage.setItem(idtienda + '_cartv32', jsonToSave);
	return {msg: 'OK', data: curData};
}

export const clearCart = () => {
	let idtienda = getTienda();
	localStorage.removeItem(idtienda + '_cartv32');
}