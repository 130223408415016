import React, { useEffect, useState, useContext, useCallback } from "react";
import { NavContext } from "@ionic/react";
import { Route } from "react-router-dom";

import { IonApp } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

import HomePage from "./pages/HomePage";
import Error404 from "./pages/Error404";

import * as firebaseService from "./store/firebaseService";

import * as cartService from "./cart";

import {Helmet} from "react-helmet";
import { useCookies } from 'react-cookie';
import CacheBuster from './components/CacheBuster';

const PublicRoutes = () => {

	// qconst [token, setToken] = useState(null);
	const [todaLaInfo, setTodaLaInfo] = useState({
		tienda: {
			id: '',
			lineas: '',
			logo_url: '',
			nombreempresa: '',
			paleta: [],
			portada_base: '',
			total_products: 0,
			total_categories: 0,
			visibility: []
		},
		categorias: [],
		tienesubcategorias: false,
		productos: [],
		carrito: {},
		cargando: true,
		subdomain: '',
	});
	
	const [cookies] = useCookies(['cliente']);
	const { navigate } = useContext(NavContext);
	
	const RetrieveImage = (ref) => {
		var baseURI = 'https://firebasestorage.googleapis.com/v0/b/micatalogovirtual-7bfd1.appspot.com/o/'+encodeURIComponent(ref.replace(/^\//, ''))+'?alt=media';
		return baseURI;
	}
	
	// const [idtiendageneral, setIdtiendageneral] = useState('');
	
	const createCookie = (name, value, days) => {
		var expires;
		if (days) {
			var date = new Date();
			date.setTime(date.getTime()+(days*24*60*60*1000));
			expires = "; expires="+date.toGMTString();
		} else {
			expires = "";
		}
		document.cookie = name+"="+value+expires+";";
	}

	const mostrar404 = useCallback(
		() => navigate('/error404', 'back'),
		[navigate]
	);

	const mostrar503 = useCallback(
		() => navigate('/error503', 'back'),
		[navigate]
	);
	
	
	useEffect( () => {
		async function callData() {
			const domain = window.location.host;
			const subdomain = domain.split('.')[0];
			// const subdomain = 'kboom';

			let idtienda = subdomain;
			// setIdtiendageneral(idtienda);
			var cambiarFallo = false;
			var esPorInternet = false;
			const tienda = await firebaseService.getTiendaInfo(idtienda).catch(err => {
				cambiarFallo = true;
				if (!window.navigator.onLine) {
					esPorInternet = true;
				}
			});
			
			if (cambiarFallo) {
				if (esPorInternet) {
					mostrar503();
				} else {
					mostrar404();
				}
				return false;
			}
			if ((!cookies.hasOwnProperty('cliente') || !cookies.cliente.hasOwnProperty('token')) && firebaseService.messaging !== null) {
				// console.log(firebaseService.messaging);
				firebaseService.messaging.requestPermission()
					.then(async function() {
						const tokenReceived = await firebaseService.messaging.getToken({vapidKey: "BBF2v0Zqi30HB1z_0etdv_cFR_IzpjJHPBQTNulVkqycJvRQQXah_IcZ_pqsKF6ODNoAqqWsDzD3CjWRLIymbQ0"});
						if (tokenReceived) {
							// alert('Test1');
							// const checkIf = await firebaseService.db.collection('clientes').where('token', '==', tokenReceived).get();
							// if (checkIf.empty) {
								// alert(tokenReceived);
							const result = await firebaseService.addObjectToCollection({collection: 'clientes', objectData: {tienetoken: true, token: tokenReceived}, currentStore: tienda});
							createCookie('cliente', JSON.stringify(result), 31);
						} else {
							// alert('test2');
							createCookie('cliente', JSON.stringify({token: true}));
						}
						// setCookie('cliente', JSON.stringify(result), { path: '/'+idtienda });
							// }
					})
					.catch(function(err) {
					  console.log("Unable to get permission to notify.", err);
					});
			}
			const cates = await firebaseService.queryObjectCollectionByPosition({ collection: "categories", currentStore: tienda });
			var ignorarPrevCates = true;
			const prevCates = cates.filter(item => {
				if (item.hasOwnProperty('parent')) {
                    if (!item.parent) {
                        return true
                    } else if (!item.hasOwnProperty('id_category')) {
						// En este caso la categoría tendrá que ser tratda distinta porque fue creada manualmente y no desde una integracion
						ignorarPrevCates = false;
						return false;
					} else {
						// En caso de que si tenga un parent, no necesariamente es una subcategoría, podrían haber seleccionado esta como la primera de la rama, así que se debe buscar si hay alguna categoría padre
						const chequeo = cates.filter(item2 => {
							if (item2.hasOwnProperty('id_category')) {
								return item2.id_category === item.parent;
							} else {
								return item2.id === item.parent;
							}
						})
						if (!chequeo.length) {
							return true;
						}
					}
                    return false;
                }
                return true;
			})
			if (prevCates.length !== cates && prevCates < cates) {
				prevCates.forEach((item, index) => {
					if (!item.hasOwnProperty('id_category')) {
						item.id_category = item.id;
					}
					var niveles = 2;
					const subcategorias = [];
					cates.forEach((cate) => {
						if (cate.hasOwnProperty('parent') && cate.parent === item.id_category) {
							// subcategorias.push(cate);

							//chequear si hay tres niveles de subcategorias
							var subsubcategorias = [];
							cates.forEach(cate2 => {
								if (cate2.hasOwnProperty('parent') && cate2.parent === cate.id_category) {
									subsubcategorias.push(cate2);
									niveles = 3;
								}
							})
							if (subsubcategorias.length) {
								cate.subcategorias = subsubcategorias;
							}
							subcategorias.unshift(cate);
						}
					})
					prevCates[index].niveles = niveles;
					prevCates[index].subcategorias = subcategorias;
				})
			}
			
			
			const products = await firebaseService.queryObjectCollection({ collection: "products", currentStore: tienda });
			
			const cart = cartService.getCart();
			
			// setFalloConexion(false);
			setTodaLaInfo({
				tienda: tienda,
				categorias: (prevCates.length || !ignorarPrevCates) ? prevCates : cates,
				productos: products,
				carrito: cart,
				cargando: false,
				subdomain
			});
		}
		if (!window.location.href.includes('error404') && !window.location.href.includes('error503')) {
			callData();
		}
	},[]); // eslint-disable-line react-hooks/exhaustive-deps
	
	return (
		<>
			<div className="application">
				<Helmet>
					{(todaLaInfo.tienda.hasOwnProperty('google') && todaLaInfo.tienda.google !== '') ? (<script
						type='text/javascript'
						src={('https://www.googletagmanager.com/gtag/js?id='+todaLaInfo.tienda.google)}
						async
					></script>) : ''}
					{(todaLaInfo.tienda.hasOwnProperty('google') && todaLaInfo.tienda.google !== '') ? (<script 
						type='text/javascript' 
						innerHTML={`window.dataLayer = window.dataLayer || [];
					  function gtag(){
						  dataLayer.push(arguments);
					  }
					  gtag('js', new Date());
						gtag('config', '`+todaLaInfo.tienda.google+`');`}
					></script>) : ''}
					
					{(todaLaInfo.tienda.hasOwnProperty('facebook') && todaLaInfo.tienda.facebook !== '') ? (<script 
						type='text/javascript' 
						innerHTML={`
  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '`+todaLaInfo.tienda.facebook+`');
  fbq('track', 'PageView');`}
					></script>) : ''}
					<meta charSet="utf-8" />
					<title>{todaLaInfo.tienda.nombreempresa} | Mi catálogo virtual</title>
					<link rel="canonical" href={window.location.href} />
					<link rel="shortcut icon" href={RetrieveImage('configuration/'+todaLaInfo.tienda.id+'/icons/72.png')} />
					<meta name="theme-color" content={todaLaInfo.tienda.hasOwnProperty('paleta') && todaLaInfo.tienda.paleta.length ? todaLaInfo.tienda.paleta[0] : '#000000'} />
					
					<meta name="apple-mobile-web-app-capable" content="yes" />
					<meta name="apple-mobile-web-app-status-bar-style" content="black" />
					<meta name="apple-mobile-web-app-title" content={todaLaInfo.tienda.nombreempresa} />
					<link rel="apple-touch-icon" href={RetrieveImage('configuration/'+todaLaInfo.tienda.id+'/icons/152.png')} />
					<meta name="description" content={"Catálogo virtual de " + todaLaInfo.tienda.nombreempresa} />
				</Helmet>
			</div>
			<IonReactRouter>
				<Route path="/:controller?/:id?" component={(params) => {
					return (params.match.params.controller === 'error404' ? <Error404 errorCode={404} errorMessage={'La página solicitada no existe.'} /> : (params.match.params.controller === 'error503' ? <Error404 errorCode={503} errorMessage={'Ha ocurrido un error con tu conexión'} /> : <HomePage info={todaLaInfo} />));
				}} />
			</IonReactRouter>
		</>
  );
};

const App = () => {
	useEffect(() => {

	})
	return (
		<CacheBuster>
			{({ loading, isLatestVersion, refreshCacheAndReload }) => {
				if (loading) return null;
				if (!loading && !isLatestVersion) {
					// You can decide how and when you want to force reload
					refreshCacheAndReload();
				}

				return (
					<IonApp>
						<PublicRoutes />
					</IonApp>
				);
			}}
		</CacheBuster>
	);
};

export default App;
