import { useHistory } from "react-router";
import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonIcon,
  IonModal,
  IonRippleEffect,
  IonTitle
} from "@ionic/react";
import { Button, Container, TextField, InputAdornment, FormControl, FormControlLabel, Checkbox  } from '@material-ui/core';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { logoWhatsapp, arrowBackOutline } from 'ionicons/icons';
import * as cartService from "./../cart";
import countries from "./../countries.json";
import {ReactComponent as TiendaIcon } from './../vectors/tienda.svg';

import * as firebaseService from "./../store/firebaseService";

import { useCookies } from 'react-cookie';

import { returnPrice } from '../utils';


const OrderPage = ({showPedidoModal, tienda, carrito}) => {
	const history = useHistory();
	
	const [countryCodeValue, setCountryCodeValue] = useState(countries[0]);
	const [countryCodeValueInput, setCountryCodeValueInput] = useState('');
	const [phone, setPhone] = useState('');
	
	const [disabledSubmit, setDisabledSubmit] = useState(true);
	
	const [datatosend, setDatatosend] = useState({
		nombre: '',
		telefono: '',
		totalpagado: 0
	});
	
	const [showModal, setShowModal] = useState(false);
	
	const [cookies] = useCookies(['cliente']);
	
	const getNewTotal = (cart) => {
		var total = 0;
		for (let i in cart) {
			total += cart[i].total;
		}
		// const current = {...datatosend};
		_setDataTosend('totalpagado', total);
	}
	
	useEffect(() => {
		getNewTotal(carrito);
	}, [carrito]); // eslint-disable-line react-hooks/exhaustive-deps
	
	
	const _setDataTosend = (key, value) => {
		const current = {...datatosend};
		current[key] = value;
		setDatatosend(current);
	}
	
	const _handleSubmit = async (event) => {
		event.preventDefault();
		const finalData = {...datatosend};
		
		finalData['telefono'] = (countryCodeValue.code + phone);
		
		finalData['products'] = {...carrito};
		let errores = false;
		Object.keys(finalData).map((val, key) => {
			if (!val) {
				errores = true;
			}
			return val;
		});
		if (errores) {
			alert('Debe completar correctamente los campos para continuar');
			return false;
		}
		const finalTienda = {...tienda};
		const result = await firebaseService.addObjectToCollection({collection: 'orders', objectData: finalData, currentStore: finalTienda});
		
		if (cookies.hasOwnProperty('cliente') && cookies.cliente.hasOwnProperty('token')) {
			const dataToUpdate = {...cookies.cliente};
			delete dataToUpdate.id;
			dataToUpdate.telefono = finalData.telefono;
			dataToUpdate.nombre = finalData.nombre;
			// console.log(cookies.cliente);
			await firebaseService.setObjectToCollection({ collection: 'clientes', id: cookies.cliente.id, objectData: dataToUpdate, currentStore: finalTienda });
		}
		
		if (result.hasOwnProperty('id')) {
			var newurl = sendToWhatsapp(result.id, finalData.products, finalData.totalpagado);
			// var newurl = sendToWhatsapp('sdadasasdasdasdsa', finalData.products, finalData.totalpagado);
			cartService.clearCart();
			history.push('/');
			window.location.href=newurl;
		}
	}
	
	const readCookie = (name) => {
		var nameEQ = name + "=";
		var ca = document.cookie.split(';');
		for(var i=0;i < ca.length;i++) {
			var c = ca[i];
			while (c.charAt(0) === ' ') {
				c = c.substring(1,c.length);
			}
			if (c.indexOf(nameEQ) === 0) {
				return c.substring(nameEQ.length,c.length);
			}
		}
		return null;
	}
	
	const createCookie = (name, value, days) => {
		var expires;
		if (days) {
			var date = new Date();
			date.setTime(date.getTime()+(days*24*60*60*1000));
			expires = "; expires="+date.toGMTString();
		}
		else {
			expires = "";
		}
		document.cookie = name+"="+value+expires+"; path=/";
	}
	
	const sendToWhatsapp = (pedido, products, totalpagado) => {
		const cart = {...products};
		let html = `Hola, quiero hacer un pedido en ${tienda.nombreempresa} 🛒 %0A%0A`;
		const domain = window.location.host;


		Object.keys(cart).forEach((key, value) => {
			const v = cart[key];
			let adiciones = '';
			if (v.hasOwnProperty('adiciones') && v.adiciones) {
				Object.keys(v.adiciones).forEach(index => {
					var info = v.adicionesInfo.find(it => it._id === index);
					var item = v.adiciones[index];
					if (info) {
						adiciones += '*'+info.tituloGrupo+':* ';
						let adicionesAr = [];
						Object.values(item).forEach(adi => {
							adicionesAr.push(adi.adicion + ' x' + adi.quantity + (adi.extraPrice > 0 ?  ' *$ ' + returnPrice(adi.quantity * adi.extraPrice) + '*' : '')); 
						})
						adiciones += adicionesAr.join(' - ');
					}
				})
			}
			html += "▪️ " + v.quantity + ' x ' + encodeURIComponent(v.name) + '' + ((v.hasOwnProperty('attributes') && v.attributes !== '') ? ' / ' + encodeURIComponent(v.attributes) : '') + (adiciones !== '' ? ' / ' + adiciones : '') + (v.hasOwnProperty('reference') && v.reference ? ' / *REF:* ' + v.reference : '') + ' / $ ' + returnPrice(v.price) + ' _x1_ / $ ' + returnPrice((v.total )) + (v.hasOwnProperty('notes') && v.notes && v.notes !== '' ? ('%0A⚠️ Observaciones: ' + encodeURIComponent(v.notes))+"%0A%0A" : "%0A%0A");
		});
		
		html += `%0ASu gran total es: $ ${returnPrice(totalpagado)} COP`;
		html += `%0A%0A` + encodeURIComponent(`*Link del pedido:* https://${domain}/revision/${pedido}`);
		// var numberToUse = '573502812684';
		// console.log(html);
		// return false;
		var numberToUse = readCookie('lineaToUse');
		if (numberToUse === null) {
			if (tienda && tienda.hasOwnProperty('lineas') && tienda.lineas && Object.keys(JSON.parse(tienda.lineas)).length) {
				var lineas = JSON.parse(tienda.lineas);
				var arrayDeLineas = Object.keys(lineas.lineas);
				if (arrayDeLineas.length) {
					var indexDeLineas = arrayDeLineas[Math.floor(Math.random() * arrayDeLineas.length)];
					createCookie('lineaToUse', lineas.lineas[indexDeLineas][1], 30);
					numberToUse = lineas.lineas[indexDeLineas][1];
				} else {
					numberToUse = '';
				}
			}
		}
		
		var url = "//api.whatsapp.com/send/?phone="+numberToUse+"&text=" + html;
		return url;
	}
	
	return (
		<IonModal mode="ios" isOpen={showPedidoModal}>
			<style>{((tienda && tienda.paleta && tienda.paleta[0]) ? `
				.addmorecombinations {
					color: ${tienda.paleta[0]};
					border-color: ${tienda.paleta[0]};
				}
			` : [])}
			</style>
			<IonModal isOpen={showModal} >
				<div className="headerModal">
					<Button onClick={() => { setShowModal(false); }} type="button" className="ion-activatable ripple-parent cartHeader">
						<IonIcon icon={arrowBackOutline} />
						<IonRippleEffect></IonRippleEffect>
					</Button>
					<IonTitle className="tituloHeader">
						Políticas de privacidad
					</IonTitle>
				</div>
				<div className="politicasContent">
					{
						(tienda.hasOwnProperty('politicasText') && tienda.politicasText) ?
						tienda.politicasText :
						''
					}
				</div>
			</IonModal>
			<IonHeader className="headerProd">
				<IonToolbar mode="ios">
					<IonButtons slot="start">
						<Button onClick={() => { history.goBack() }} type="button" className="ion-activatable ripple-parent cartHeader">
							<IonIcon icon={arrowBackOutline} />
							<IonRippleEffect></IonRippleEffect>
						</Button>
					</IonButtons>
					<IonTitle>
						Enviar Orden
					</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent className="cartSection">
				<section className="formInfo">
					<Container className="containerCarts">
						<form method="POST" onSubmit={(e) => {_handleSubmit(e)}}>
							<FormControl>
								 <TextField
								  id="filled-number"
								  label="Ingresa tu nombre"
								  required={true}
								  className="width100"
								  type="text"
								  onInput={(e) => { _setDataTosend('nombre', e.target.value) }}
								  InputLabelProps={{
									shrink: true,
								  }}
								  variant="outlined"
								  color="secondary"
								/>
							</FormControl>
							<FormControl className="flexible contenedorPhone">
								<Autocomplete
									value={countryCodeValue}
									onChange={(event, newValue) => {
										setCountryCodeValue(newValue);
									}}
									inputValue={countryCodeValueInput}
									onInputChange={(event, newInputValue) => {
										setCountryCodeValueInput(newInputValue);
									}}
									options={countries}
									getOptionLabel={(option) =>	option.code}
									renderOption={(option) => (
										<>
										  {option.name} ({option.code})
										</>
									)}
									renderInput={(params) => {
										const paramsF = {...params};
										paramsF.InputProps.startAdornment=(<InputAdornment position="start">
											<IonIcon
												icon={logoWhatsapp}
											/>
										  </InputAdornment>
										)
										return (<TextField {...paramsF}
												label="Ingresa tu número de WhatsApp"
												variant="outlined"
												color="secondary"
												type="text"
												required={true}
											/>)
										}
									}
								/>
								<input
									name="numerofinal"
									type="text"
									placeholder="Ej: 300 300 30 30"
									className="nextTo"
									required={true}
									onInput={(e) => { setPhone(e.target.value) }}
								/>
							</FormControl>
							
							<div className="infoAsesor">
								<TiendaIcon /> Un asesor confirmará tu orden por Whatsapp y te ayudará con el proceso de pago.
							</div>
							
							<div className="infoAsesor">
								<FormControlLabel
									control={
									  <Checkbox
										icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
										checkedIcon={<CheckBoxIcon fontSize="small" />}
										name="aceptoterminos"
										onChange={(e) => { setDisabledSubmit(!e.target.checked) }}
									  />
									}
									label={(<div className="BotonAcepto">He leído y acepto los <button onClick={() => { setShowModal(true); }}><span>las políticas de privacidad</span></button></div>)}
								  />
							</div>
							<button disabled={disabledSubmit} type="submit" className={"whatsappButton cartfooterbutton ripple-parent ion-activatable " + (Object.keys(carrito).length ? '' : 'disabled')}>
								<IonIcon icon={logoWhatsapp} /> Enviar a WhatsApp
								<IonRippleEffect></IonRippleEffect>
							</button>
						</form>
					</Container>
				</section>
			</IonContent>
		</IonModal>
	);
}

export default OrderPage;