import React from "react";

import {
  IonContent,
  IonPage,
  IonImg,
  IonIcon
} from "@ionic/react";
import { reload, home } from 'ionicons/icons';

const Error404 = ({errorCode, errorMessage}) => {	
	return (
		<IonPage>
			<IonContent style={{textAlign: 'center'}}>
                <IonImg style={{maxWidth:'150px', margin: '20px auto 30px'}} src="/img/error.png" />
                <div style={{ fontSize: '42px', fontWeight: 'bold', textTransform: 'uppercase' }}>Error {errorCode}</div>
                <div>{errorMessage}</div>
                
                {errorCode === 404
                    ? <a style={{ margin: '30px auto', maxWidth: '200px', borderRadius: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center', textDecoration: 'none', background: '#0099bf', color: '#FFF', height: '40px' }} href="https://micatalogovirtual.com/">Ir al inicio <IonIcon style={{ fontSize: '20px', marginLeft: '5px' }} icon={home} slot="end" /> </a>
                    : <a style={{ margin: '30px auto', maxWidth: '200px', borderRadius: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center', textDecoration: 'none', background: '#0099bf', color: '#FFF', height: '40px' }} href="/">Volver a cargar <IonIcon style={{ fontSize: '20px', marginLeft: '5px' }} icon={reload} slot="end" /> </a>
                }
			</IonContent>
		</IonPage>
	);
};

export default Error404;
