import { useHistory } from "react-router";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonImg,
  IonIcon,
  IonModal,
  IonSkeletonText,
  IonRippleEffect,
  IonFooter,
  IonTitle
} from "@ionic/react";
import { Button, Typography, Grid, Container } from '@material-ui/core';
import { addOutline, removeOutline, logoWhatsapp, arrowBackOutline, closeOutline } from 'ionicons/icons';
import RetrieveImage from './../retrieveImg';
import * as cartService from "./../cart";
import {ReactComponent as VacioIcon } from './../vectors/carritovacio.svg';
import { returnPrice } from '../utils';

const CartPage = ({showCarritoModal, tienda, carrito}) => {
	const history = useHistory();
	
	const [totalToPay, setTotalToPay] = useState(0);
	const [carritoTwo, setCarritoTwo] = useState({});
	
	useEffect(() => {
		setCarritoTwo(carrito);
		getNewTotal(carrito);
	}, [carrito]);
	
	const getNewTotal = (cart) => {
		var total = 0;
		for (let i in cart) {
			total += cart[i].total;
		}
		setTotalToPay(total);
	}
	
	const upCart = (cart, index) => {
		const result = cartService.upCart(cart, index);
		if (result.msg !== 'OK') {
			alert(result.msg);
		} else {
			setCarritoTwo(result.data);
			getNewTotal(result.data);
		}
	}
	
	const downCart = (cart, index) => {
		const result = cartService.downCart(cart, index);
		if (result.msg !== 'OK') {
			alert(result.msg);
		} else {
			setCarritoTwo(result.data);
			getNewTotal(result.data);
		}
	}
	
	const deleteCart = (index) => {
		if (window.confirm('¿Está seguro de eliminar este producto?')) {
			const result = cartService.borrarCart(index);
			if (result.msg !== 'OK') {
				alert(result.msg);
			} else {
				setCarritoTwo(result.data);
				getNewTotal(result.data);
			}
		}
	}
	
	const _renderCartItems = () => {
		const cartToUse = {...carritoTwo};
		return Object.keys(cartToUse).map(k => {
			let cart = cartToUse[k];
			if (cart) {
				return (
					<div className="cardNueva" key={k}>
				 		<Grid container justify="flex-start" spacing={2}>
				 			<Grid item xs={4}>
				 				{
				 					(cart.cover && typeof cart.cover === 'string' && cart.cover !== '') ?
				 					<Link to={"/product/" + cart.id}><IonImg src={RetrieveImage(cart.cover)} className="cartImg" /></Link> :
				 					((cart.cover && typeof cart.cover === 'object') ? 
				 						<Link to={"/product/" + cart.id}><IonImg src={RetrieveImage(cart.cover[0])} className="cartImg" /></Link> :
				 						<IonSkeletonText animated className="cartExample" />
				 					)
				 				}
				 			</Grid>
				 			<Grid item xs={8} className="containerForCartInfo">
				 				<Typography variant="body1" className="tituloname" component="p">
				 					{cart.name.toUpperCase()}
				 				</Typography>
				 				{cart.hasOwnProperty('reference') && cart.reference ? (
				 					<Typography variant="body2" component="p" className="infomini">
				 						<strong>Referencia:</strong> {cart.reference}
				 					</Typography>	
				 				) : ''}
				 				<Typography variant="body2" component="p" className="infomini">
				 					<strong>Cantidad:</strong> {cart.quantity}
				 				</Typography>
				 				{tienda.hasOwnProperty('visibility') && tienda.visibility.includes('precio') ? <Typography color="textSecondary" variant="body2" component="p" className="infomini">
				 					<strong>Precio x1:</strong> $ {returnPrice(cart.price)}
				 				</Typography> : null }
				 				{cart.hasOwnProperty('attributes') && cart.attributes.length ? (<Typography className="infomini" color="textSecondary" variant="body2" component="p">
				 					<strong>Atributos:</strong> {cart.attributes}
				 				</Typography>) : ''}
				 				{cart.hasOwnProperty('adiciones') && cart.hasOwnProperty('adicionesInfo') && cart.adiciones !== null && cart.adicionesInfo !== null && Object.keys(cart.adiciones).length ? (<><Typography className="infomini" color="textSecondary" variant="body2" component="p">
				 					<strong>Especificaciones:</strong>
				 				</Typography>
				 				<ul className="listaUl">
				 					{Object.keys(cart.adiciones).map(index => {
				 						var info = cart.adicionesInfo.find(it => it._id === index);
				 						var item = cart.adiciones[index];
										if (info) {
											return (
												<li key={info._id}>
													<strong>{info.tituloGrupo}:</strong>
													{Object.values(item).map(adi => {
														return (
															<span key={info._id + adi.adicion}>
																{adi.adicion} <i>x{adi.quantity}</i>
																{adi.extraPrice > 0 ? <strong> + ${returnPrice(adi.quantity * adi.extraPrice)}</strong> : ''}
															</span>
														)
													})}
												</li>
											)
										} else {
											return null;
										}
				 					})}	
				 				</ul>
				 				</>) : ''}
				 				{cart.hasOwnProperty('notes') && cart.notes.trim().length ? (<Typography className="infomini" color="textSecondary" variant="body2" component="p">
				 					<strong>observaciones:</strong><br /> {cart.notes}
				 				</Typography>) : ''}
								
				 				<button onClick={() => { deleteCart(k) }} className="botonEliminar ion-activatable ripple-parent">
				 					<IonIcon icon={closeOutline} />
				 					<IonRippleEffect></IonRippleEffect>
				 				</button>
								
				 				<div className="pull-to-right">
				 					<div className="formToUp">
				 						<button className="iconToUp ion-activatable ripple-parent" onClick={() => {downCart(cart, k)}}>
				 							<IonIcon icon={removeOutline} />
				 							<IonRippleEffect></IonRippleEffect>
				 						</button>
				 						<span className="quantityInfo">{cart.quantity}</span>
				 						<button className="iconToUp ion-activatable ripple-parent" onClick={() => {upCart(cart, k)}}>
				 							<IonIcon icon={addOutline} />
				 							<IonRippleEffect></IonRippleEffect>
				 						</button>
				 					</div>
				 					{tienda.hasOwnProperty('visibility') && tienda.visibility.includes('precio') ? <span className="finalPrice">$ {returnPrice(cart.total)}</span> : null}
				 				</div>
				 			</Grid>
				 		</Grid>
				 	</div>
				);
			} else {
				return null;
			}
		})
	}
	
	return (
		<IonModal mode="ios" isOpen={showCarritoModal}>
			<style>{((tienda && tienda.paleta && tienda.paleta[0]) ? `
				.addmorecombinations {
					color: ${tienda.paleta[0]};
					border-color: ${tienda.paleta[0]};
				}
				
				.whatsappButton {
					background: ${tienda.paleta[0]};
				}
			` : [])}
			</style>
			<IonHeader className="headerProd">
				<IonToolbar mode="ios">
					<IonButtons slot="start">
						<Button onClick={() => { if (history.length > 2) { history.goBack(); } else { history.push('/'); }  }} type="button" className="ion-activatable ripple-parent cartHeader">
							<IonIcon icon={arrowBackOutline} />
							<IonRippleEffect></IonRippleEffect>
						</Button>
					</IonButtons>
					<IonTitle>
						Mi Bolsa
					</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent className="cartSection">
				<section className="carritoInfo">
					<Typography className="textoCuantosCarritos" align="center">
						Hay {Object.keys(carritoTwo).length} producto{Object.keys(carritoTwo).length > 1 ? 's' : ''} en mi bolsa
					</Typography>
					<Container className="containerCarts">
						{Object.keys(carritoTwo).length ? _renderCartItems() : (
							<VacioIcon />
						)}
					</Container>
				</section>
			</IonContent>
			<IonFooter>
				<IonToolbar>
					<div className="footerInfo">
					{tienda.hasOwnProperty('visibility') && tienda.visibility.includes('precio') ? <Grid container justify="space-between">
							<Grid item>
								<Typography variant="h6" component="p">
									SubTotal
								</Typography>
							</Grid>
							<Grid item>
								<Typography variant="h5" align="right">
									$ {returnPrice(totalToPay)}
								</Typography>
							</Grid>
						</Grid> : null }
						{(tienda.hasOwnProperty('mostrarEnvio') && tienda.mostrarEnvio) ? (<Grid container justify="space-between">
							<Grid item>
								<Typography variant="body1">
									Envío
								</Typography>
							</Grid>
							<Grid item>
								<Typography color="textSecondary" variant="body1">
									{(tienda.hasOwnProperty('envioText') && tienda.envioText) ? tienda.envioText : '+Costos de envío'}
								</Typography>
							</Grid>
						</Grid>) : ''}
						{(tienda.hasOwnProperty('mostrarEntrega') && tienda.mostrarEntrega) ? (<Grid container justify="space-between">
							<Grid item>
								<Typography variant="body1">
									Entrega estimada
								</Typography>
							</Grid>
							<Grid item>
								<Typography color="textSecondary" variant="body1">
									{(tienda.hasOwnProperty('entregaText') && tienda.entregaText) ? tienda.entregaText : 'De 2 a 5 días'}
								</Typography>
							</Grid>
						</Grid>) : ''}
						<Grid container direction="column" className="margin-top-25">
						{tienda.hasOwnProperty('visibility') && tienda.visibility.includes('precio') ?
							<Link to={"/order"} className={"whatsappButton cartfooterbutton ripple-parent ion-activatable " + ((parseInt(totalToPay) > 0) ? '' : 'disabled')}>
								<IonIcon icon={logoWhatsapp} /> {(tienda.hasOwnProperty('botondeorden') && tienda.botondeorden !== '') ? tienda.botondeorden : 'Comprar por WhatsApp'}
								<IonRippleEffect></IonRippleEffect>
							</Link>
							: <Link to={"/order"} className={"whatsappButton cartfooterbutton ripple-parent ion-activatable "}>
								<IonIcon icon={logoWhatsapp} /> {(tienda.hasOwnProperty('botondeorden') && tienda.botondeorden !== '') ? tienda.botondeorden : 'Comprar por WhatsApp'}
								<IonRippleEffect></IonRippleEffect>
							</Link>
						}
							<Link to={"/"} className="comprarmasButton cartfooterbutton ripple-parent ion-activatable">
								Añadir más productos
								<IonRippleEffect></IonRippleEffect>
							</Link>
						</Grid>
					</div>
				</IonToolbar>
			</IonFooter>
		</IonModal>
	);
}

export default CartPage;